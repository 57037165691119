/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const SelectInput = ({ item, control, errors, defaultValues, setValue }) => {
	useEffect(() => {
		if (defaultValues[item.name]) {
			setValue(item.name, defaultValues[item.name]);
		}
	}, [defaultValues[item.name]]);

	const getSelectedOptions = (value = null) => {
		if (defaultValues[item.name] || value) {
			const finalValue = item.options.find((el) => {
				return el.value === value ? value : defaultValues[item.name];
			});
			// setValue(finalValue.value);
			return finalValue;
		}
		// setValue(null);
		return null;
	};

	return (
		<div className="mb-2 select-wrapper">
			<Controller
				render={(props) => {
					return (
						<Select
							options={item.options}
							name={item.name}
							placeholder={item.placeholder}
							isClearable
							isMulti={item.isMulti}
							control={control}
							errors={errors}
							defaultValue={getSelectedOptions()}
							// menuPortalTarget={document.body}
							styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
							menuPosition="fixed"
							portalPlacement="bottom"
							required
							onChange={(data) => {
								let result = null;
								if (Array.isArray(data)) {
									result = data && data.map((el) => el.value);
								} else {
									result = data && data.value;
								}
								setValue(result);
								props.onChange(result);
							}}
							// value={getSelectedOptions(props.value)}
						/>
					);
				}}
				name={item.name}
				control={control}
			/>
		</div>
	);
};

SelectInput.propTypes = {
	item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SelectInput;
