import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';

const MediaWidget = ({ src, className, title, subtitle, classNameMedia }) => {
	return (
		<Media className={className}>
			{src && (
				<Media
					object
					src={src}
					alt="Generic placeholder image"
					className={classNameMedia}
				/>
			)}

			<Media body className="ml-3">
				<h6 className="font-weight-bold">{title}</h6>
				{subtitle}
			</Media>
		</Media>
	);
};

MediaWidget.propTypes = {
	src: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	classNameMedia: PropTypes.string,
};

MediaWidget.defaultProps = {
	src: '',
	className: '',
	title: '',
	subtitle: '',
	classNameMedia: 'rounded-circle w-25',
};

export default MediaWidget;
