/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import ArrayItem from './ArrayItem';

const ArrayType = ({
	item,
	register,
	setValue,
	defaultValues,
	errors,
	watch,
	control,
}) => {
	const [indexes, setIndexes] = React.useState([]);
	const [counter, setCounter] = React.useState(0);

	const addRow = () => {
		setIndexes((prevIndexes) => [...prevIndexes, counter]);
		setCounter((prevCounter) => prevCounter + 1);
	};

	const removeRow = (index) => () => {
		// if (indexes.length === 1) return;
		setIndexes((prevIndexes) => [...prevIndexes.filter((i) => i !== index)]);
		setCounter((prevCounter) => prevCounter - 1);
	};

	useEffect(() => {
		addRow();
	}, []);

	return (
		<Row>
			<Col xs={12}>
				<div className="field-wrapper">
					<div className="field-controls">
						<div className="field-title">
							<span>
								{item.name.includes('.')
									? item.name.split('.')[item.name.split('.').length - 1]
									: item.name}
							</span>
						</div>
						<div className="field-button">
							<button
								type="button"
								className="btn btn-outline-success mr-1 w-btn"
								onClick={addRow}
							>
								+
							</button>
						</div>
					</div>

					<Row className="d-flex align-items-center">
						{indexes.map((index) => {
							const fieldName = `${item.name}[${index}]`;
							return (
								<Col xs={12} md={6} key={fieldName}>
									<div className="field-wrapper">
										<div className="field-controls">
											<div className="field-title">
												<span>{`${
													item.name.includes('.')
														? item.name.split('.')[
																item.name.split('.').length - 1
														  ]
														: item.name
												} #${index + 1}`}</span>
											</div>
											<div className="field-button">
												<button
													type="button"
													className="btn btn-outline-success mr-1 w-btn"
													onClick={addRow}
												>
													+
												</button>

												<button
													type="button"
													className="btn btn-outline-danger w-btn"
													onClick={removeRow(index)}
												>
													-
												</button>
											</div>
										</div>

										<ArrayItem
											fields={item.fields}
											register={register}
											fieldName={fieldName}
											setValue={setValue}
											defaultValues={defaultValues}
											errors={errors}
											watch={watch}
											type={item.type}
											control={control}
										/>
									</div>
								</Col>
							);
						})}
					</Row>
				</div>
			</Col>
		</Row>
	);
};

ArrayType.propTypes = {
	item: PropTypes.objectOf(PropTypes.any).isRequired,
};

ArrayType.defaultProps = {};

export default ArrayType;
