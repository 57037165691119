import React, { useState } from 'react';
import { FaUpload } from 'react-icons/fa';

export default function FileInput({
	name,
	label,
	required,
	register,
	setValue,
}) {
	const [fileName, setFileName] = useState('');
	const hiddenFileInput = React.useRef(null);
	const handleClick = (event) => {
		event.preventDefault();
		hiddenFileInput.current.click();
	};
	let fileReader;
	const handleFileReader = () => {
		// onChange(fileReader.result);
		setValue(name, btoa(fileReader.result));
	};
	const handleFileChosen = (file) => {
		setFileName(file.name);
		fileReader = new FileReader();
		fileReader.onloadend = handleFileReader;
		fileReader.readAsDataURL(file);
	};
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<input
				type="text"
				className="form-control"
				readOnly
				value={fileName}
				onClick={handleClick}
			/>

			<button
				className="btn"
				type="button"
				onClick={handleClick}
				id="fileButton"
			>
				<FaUpload />
			</button>
			<input
				className="custom-file-input1"
				type="hidden"
				name={name}
				ref={register}
			/>
			<input
				ref={hiddenFileInput}
				className="d-none"
				type="file"
				name={name}
				label={label}
				required={required}
				id="file"
				accept="*"
				onChange={(e) => handleFileChosen(e.target.files[0])}
			/>
		</div>
	);
}
