/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';

function Headings(props) {
	const { tag, className, children, id } = props;
	return (
		<>
			{tag === 'h1' && (
				<h1 className={className} id={id}>
					{children}
				</h1>
			)}
			{tag === 'h2' && (
				<h2 className={className} id={id}>
					{children}
				</h2>
			)}
			{tag === 'h3' && (
				<h3 className={className} id={id}>
					{children}
				</h3>
			)}
			{tag === 'h4' && (
				<h4 className={className} id={id}>
					{children}
				</h4>
			)}
			{tag === 'h5' && (
				<h5 className={className} id={id}>
					{children}
				</h5>
			)}
			{tag === 'h6' && (
				<h6 className={className} id={id}>
					{children}
				</h6>
			)}
			{tag === 'p' && (
				<p className={className} id={id}>
					{children}
				</p>
			)}
		</>
	);
}

Headings.propTypes = {
	tag: PropTypes.string.isRequired,
	className: PropTypes.string,
	id: PropTypes.string,
};

Headings.defaultProps = {
	className: '',
	id: '',
};

export default Headings;
