// import Ajv from 'ajv';
// import Schema from '../../data/Schemas/createCustomerAccountSchema.json';

function imageParser(data) {
	let content = data;
	if (typeof content !== 'undefined' && content !== null) {
		content = content.replace(/<h2>/g, '<h2 class="display-4">');
		content = content.replace(
			/<figure class="image"/g,
			'<figure class="figure" '
		);
		content = content.replace(/<img/g, '<img class="figure-img img-fluid" ');
		return content;
	}
	return data;
}

function codeParser(data) {
	let content = data;
	if (typeof content !== 'undefined' && content !== null) {
		const clossingBlockquote = new RegExp('</blockquote>', 'gi');
		const clossingP = new RegExp('</p>', 'gi');
		const spaces = new RegExp('&nbsp;', 'gi');
		content = content.replace(/<blockquote>/g, '');
		content = content.replace(clossingBlockquote, '');
		content = content.replace(/<p>/g, '');
		content = content.replace(clossingP, ' \n ');
		content = content.replace(spaces, ' ');

		return content;
	}
	return null;
}

// function validateWithSchema(content) {
//   const ajv = new Ajv({ allErrors: true });
//   const validate = ajv.compile(Schema);
//   try {
//     const valid = validate(content);
//     if (!valid) return validate.errors;
//     return content;
//   } catch (error) {
//     return error;
//   }
// }
function contentToJson(content) {
	let codeToShow = content || '';
	if (content && content.includes('<pre><code')) {
		codeToShow = content.match(/<pre><code [^>]+>(.*)<\/code><\/pre>/s);
	}

	return JSON.parse(Array.isArray(codeToShow) ? codeToShow[1] : codeToShow);

	// return validateWithSchema(
	//   JSON.parse(Array.isArray(codeToShow) ? codeToShow[1] : codeToShow)
	// );
}
function getOnlyContent(content) {
	let codeToShow = content || '';
	if (content && content.includes('<pre><code')) {
		codeToShow = content.match(/<pre><code [^>]+>(.*)<\/code><\/pre>/s);
	}

	return Array.isArray(codeToShow) ? codeToShow[1] : codeToShow;
}

function getLanguageToCode(content) {
	const codeLang = content && content.match(/<code class="language-(.*?)">/);

	if (Array.isArray(codeLang)) {
		return codeLang[1];
	}
	return null;
}

export default {
	imageParser,
	codeParser,
	contentToJson,
	getLanguageToCode,
	getOnlyContent,
};
