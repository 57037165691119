/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { navigate } from '@reach/router';
// import useReduxHandler from '../../../../../hooks/useReduxHandler';
import ModalWidget from '../../../sections/ModalWidget';
import EditorWidget from '../../../../shared/Editor';

const CTAManager = ({ ctas }) => {
	// const {
	// 	toggleOpenContactModal,
	// 	toggleOpenMeetingModal,
	// 	getUser,
	// 	setLoading,
	// } = useReduxHandler();

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const [modalChildren, setModalChildren] = useState(null);

	const onClickHandler = (cta) => {
		if (cta.type === 'MEETING') {
			// toggleOpenMeetingModal();
			if (typeof window !== 'undefined') {
				window.dataLayer.push({
					event: 'button_click',
					clicked_button_name: 'Connect',
				});
			}
		} else if (cta.type === 'LINK') {
			const url = cta.page ? `/${cta.page.slug}` : `/${cta.page.slug}`;
			navigate(url);
		} else if (cta.type === 'CONTACT') {
			// toggleOpenContactModal();
		} else if (cta.type === 'CREATEPDF') {
			const requestOptions = {
				method: 'GET',
				headers: {
					// 'x-api-key': getUser() !== -1 ? getUser().token : '',
					'content-Type': 'application/json',
				},
				redirect: 'follow',
			};
			// setLoading(true);
			fetch(cta.url, requestOptions)
				.then((response) => {
					return response.json();
				})
				.then((result) => {
					setModalChildren(result);
					toggle();
					// setLoading(false);
				})
				.catch(() => {
					setModalChildren(null);
					// setLoading(false);
				});
		}
	};
	let count = 0;

	return (
		<>
			<div
				className={`row justify-content-${
					ctas.length === 1 ? 'start' : 'center'
				}`}
			>
				{ctas &&
					ctas.length > 0 &&
					ctas.map((cta) => {
						// eslint-disable-next-line no-plusplus
						count++;
						const arrowIcon = cta.text.includes('->');
						const text = cta.text.replace('->', '');
						return (
							<div
								className={`d-flex justify-content-center justify-content-md-${
									ctas.length === 1 ? 'start' : count === 1 ? 'end' : 'start'
								} pr-md-2 col-12 col-sm-6`}
								key={cta.text}
							>
								<button
									type="button"
									className={`cta-btn ${cta.classname}`}
									onClick={() => {
										if (typeof window !== 'undefined' && window.dataLayer) {
											window.dataLayer.push({
												event: 'button_click',
												clicked_button_name: 'Connect',
											});
										}
										onClickHandler(cta);
									}}
									// style={{ width: '200px' }}
								>
									{text} {arrowIcon && <AiOutlineArrowRight />}
								</button>
							</div>
						);
					})}
			</div>
			{modalChildren && (
				<ModalWidget isOpen={isOpen} toggle={toggle} size="lg">
					<EditorWidget
						code={JSON.stringify(modalChildren, null, 4)}
						responseHeight="500px"
					/>
				</ModalWidget>
			)}
		</>
	);
};

CTAManager.propTypes = {
	ctas: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CTAManager;
