import React from 'react';
import PropTypes from 'prop-types';
// import LoadingBar from 'react-redux-loading-bar';
import NavbarWidget from '../Navbar';
import MainSectionHomePage from '../../containers/sections/MainSectionHomePage';
import FooterWidget from '../../containers/sections/Footer';
// import ScheduledMeeting from '../../containers/sections/ScheduledMeeting/ScheduledMeeting';
// import ContactModal from '../../containers/sections/ContactModal';
// import MemberDetail from '../MemberDetail';
// import Fontfaces from '../../../styles/importFonts';

// const LayoutPublic = (props) => {
// 	const {
// 		title,
// 		header,
// 		viewMainSection,
// 		mainSection,
// 		classNameMain,
// 		menu,
// 		footerTopMenu,
// 		footerBottomMenu,
// 		path,
// 		children,
// 		isLogged,
// 	} = props;

const LayoutPublic = (props) => {
	const {
		title,
		header,
		viewMainSection,
		mainSection,
		// footerTopMenu,
		footerBottomMenu,
		classNameMain,
		menu,
		path,
		children,
		isLogged,
	} = props;

	const classStyle = viewMainSection
		? `${
				mainSection && mainSection.classStyle && mainSection.classStyle[0]
		  } shadow`
		: 'shadow';

	return (
		<>
			{/* <Fontfaces /> */}
			{/* <LoadingBar showFastActions className="loading-bar" /> */}
			<NavbarWidget
				siteTitle={title}
				color={header}
				menu={menu}
				isPrivate={false}
				path={path}
				classStyle={classStyle}
				isLogged={isLogged}
				isIspConnectText={false}
			/>
			<header>
				{viewMainSection && <MainSectionHomePage data={mainSection} />}
			</header>
			<main className={classNameMain}>{children}</main>
			<footer className="bg-custom-dark mt-auto footer py-5 w-100">
				<FooterWidget
					// footerTopMenu={footerTopMenu}
					footerBottomMenu={footerBottomMenu}
				/>
			</footer>
			{/* <ScheduledMeeting />
			<ContactModal />
			<MemberDetail /> */}
		</>
	);
};

LayoutPublic.propTypes = {
	title: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired,
	viewMainSection: PropTypes.bool.isRequired,
	mainSection: PropTypes.objectOf(PropTypes.any),
	classNameMain: PropTypes.string.isRequired,
};

LayoutPublic.defaultProps = {
	mainSection: null,
};

export default LayoutPublic;
