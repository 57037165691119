const getTabSize = (tabSize) => {
	switch (tabSize) {
		case 'Level_3':
			return 3;
		case 'Level_4':
			return 4;

		default:
			return 0;
	}
};

module.exports = { getTabSize };
