/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import Headings from '../Headings/Headings';

function Title(props) {
	const {
		headingTitle,
		headingSubTitle,
		hasTitle,
		title,
		classNameTitle,
		hasSubTitle,
		subtitle,
		classNameSubTitle,
		className,
		style,
		idTitle,
	} = props;
	return (
		<div className={className} style={style}>
			{hasTitle && (
				<Headings tag={headingTitle} className={classNameTitle} id={idTitle}>
					{title}
				</Headings>
			)}
			{hasSubTitle && (
				<Headings tag={headingSubTitle} className={classNameSubTitle}>
					{subtitle}
				</Headings>
			)}
		</div>
	);
}

Title.propTypes = {
	headingTitle: PropTypes.string,
	headingSubTitle: PropTypes.string,
	hasTitle: PropTypes.bool,
	title: PropTypes.string,
	classNameTitle: PropTypes.string,
	hasSubTitle: PropTypes.bool,
	subtitle: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.any),
	]),
	classNameSubTitle: PropTypes.string,
	className: PropTypes.string,
	style: PropTypes.objectOf(PropTypes.any),
	idTitle: PropTypes.string,
};

Title.defaultProps = {
	headingTitle: 'h1',
	headingSubTitle: 'h5',
	hasTitle: true,
	title: 'Title',
	classNameTitle: '',
	hasSubTitle: false,
	subtitle: '',
	classNameSubTitle: '',
	className: '',
	style: null,
	idTitle: '',
};

export default Title;
