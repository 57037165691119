/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
// import useReduxHandler from '../../../../hooks/useReduxHandler';

function ColContainer({
	children,
	className,
	id,
	xs,
	md,
	backendName,
	sidebarTitle,
	changeVisibility,
	type,
}) {
	// const [color, setColor] = useState('red');
	const [containmentDOMRect, setContainmentDOMRect] = useState(null);
	// const { setActiveItem } = useReduxHandler();

	// function onChange(isVisible) {
	// 	if (isVisible && sidebarTitle) {
	// 		setActiveItem(id);
	// 	}
	// 	changeVisibility(id, isVisible);
	// }
	useEffect(() => {
		if (type !== 'CSV' && type !== 'PDF') {
			setContainmentDOMRect(document.getElementById('viewportSensor'));
		}
	}, []);

	return (
		<Col
			className={className}
			id={id}
			xs={xs}
			md={md}
			data-backend-name={backendName || null}
		>
			{children}
		</Col>
	);
}

ColContainer.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	// eslint-disable-next-line react/no-unused-prop-types
	isPrivate: PropTypes.bool,
};

ColContainer.defaultProps = {
	className: 'my-5',
	id: null,
	isPrivate: false,
};
// const MySection = handleViewport(ColContainer, {
//   rootMargin: '100px',
//   threshold: 1
// });

// export default MySection;

export default ColContainer;
