/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
// import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import contentParser from '../../../../utils/contentParser';
import FormField from '../../../../shared/FormField';
import { DispatchContext, StateContext } from '../../../../../context';
import { getCfrToken, sendConnectData } from '../../../../utils/requestUtils';

const FormType = ({ content }) => {
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const fields = contentParser.contentToJson(content);

	const {
		register,
		errors,
		handleSubmit,
		watch,
		setValue,
		getValues,
		control,
	} = useForm();

	useEffect(() => {
		const didMount = async () => {
			if (typeof window !== 'undefined') {
				await getCfrToken({
					tokens: { 'x-api-key': 'tok_16219740844720612Lqw6fDlHpGb' },
					dispatch,
				});
			}
		};
		didMount();
	}, []);

	useEffect(() => {
		if (state.isSubmitted) {
			console.log('form submitted');
			navigate('/success');
		}
	}, [state.isSubmitted]);

	const onSubmit = async (values) => {
		await sendConnectData({
			tokens: { 'x-api-key': 'tok_16219740844720612Lqw6fDlHpGb' },
			cfrToken: state.cfrToken,
			requestObject: { ...values },
			dispatch,
		});
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				noValidate
				className="p-4"
				style={{ border: '3px solid white' }}
			>
				{fields && fields.length > 0 && (
					<Row>
						{fields.map((item, index) => {
							return (
								<>
									{index === 0 && (
										<Col md="12" className="mb-4">
											<h3 className="text-center text-white">
												Let&apos;s Connect
											</h3>
											<p className="text-center">
												We&apos;d be delighted to explore a partnership.
											</p>
										</Col>
									)}
									<Col
										xs="12"
										key={JSON.stringify(item)}
										className={`mb-2 ${item.type === 'HIDDEN' ? 'd-none' : ''}`}
									>
										<FormField
											item={item}
											register={register}
											errors={errors}
											watch={watch}
											setValue={setValue}
											defaultValues={
												item.defaultValue
													? { [item.name]: item.defaultValue }
													: ''
											}
											control={control}
											valueInput={getValues(item.name)}
											showinfoIcon
										/>
									</Col>
								</>
							);
						})}
					</Row>
				)}

				<Row>
					<Col className="d-flex align-items-end justify-content-center">
						<button
							type="submit"
							className="btn btn-primary btn-send-response mt-4"
							// disabled={isRecordLoading()}
						>
							Submit
						</button>
					</Col>
				</Row>
			</form>
		</>
	);
};

FormType.propTypes = {
	content: PropTypes.string,
};
FormType.defaultProps = {
	content: '',
};

export default FormType;
