export const initialState = {
	loadingCfrToken: false,
	cfrToken: null,
	missingDataErrorMessage: null,
	notes: null,
	fields: [],
	hash: '',
	isSubmitted: false,
};

const globalReducer = (state, action) => {
	console.log(
		`[ACTION]: ${action.type} : [PAYLOAD]: ${
			action.type === 'setBillers' ? 'billers' : JSON.stringify(action.payload)
		} `
	);
	switch (action.type) {
		case 'setLoadingCfrToken': {
			return { ...state, loadingCfrToken: action.payload };
		}
		case 'setCfrToken': {
			return { ...state, cfrToken: action.payload };
		}
		case 'setNotes': {
			return { ...state, notes: action.payload };
		}
		case 'setHash': {
			return { ...state, hash: action.payload };
		}
		case 'setMissingDataErrorMessage': {
			return { ...state, missingDataErrorMessage: action.payload };
		}
		case 'setIsSubmitted': {
			return { ...state, isSubmitted: action.payload };
		}
		case 'setNotesAndFields': {
			return {
				...state,
				notes: action.payload.notes,
				fields: action.payload.fields,
			};
		}
		default:
			throw new Error(`Action [${action.type}] not exist`);
	}
};

export default globalReducer;
