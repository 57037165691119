/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ErrorMessage } from '@hookform/error-message';

const PlacesAutoComplete = (props) => {
	const {
		register,
		name,
		required,
		label,
		inputId,
		watch,
		errors,
		setValue,
		showClearButton,
		readOnly,
	} = props;
	const [focus, setFocus] = useState('');
	const inputRef = useRef(null);
	let autocomplete = null;

	const id = inputId || `input-${name}`;
	const watchField = watch && watch(name);

	const fillInAddress = () => {
		if (autocomplete) {
			const place = autocomplete.getPlace();
			if (place) {
				let street = '';
				let state = '';
				let city = '';
				let zipCode = '';
				for (const component of place.address_components) {
					const componentType = component.types[0];

					switch (componentType) {
						case 'street_number': {
							street = component.long_name;
							break;
						}
						case 'route': {
							street = component.long_name;
							break;
						}
						case 'postal_code': {
							zipCode = component.long_name;
							break;
						}
						case 'locality':
							city = component.long_name;
							break;

						case 'administrative_area_level_1': {
							state = component.long_name;
							break;
						}
					}
				}
				if (!street || !state || !city || !zipCode) {
					// helpers.setError('Address is not complete');
				} else {
					setValue(`${street} ${city} ${state} ${zipCode}`);
					// config.setAddress({ street, state, city, zip_code: zipCode });
				}
			} else {
				// helpers.setError('Address is not complete');
			}
		} else {
			// helpers.setError('Address is not complete');
		}
	};

	const initAutocomplete = () => {
		if (typeof window !== 'undefined' && window.google && window.google.maps) {
			autocomplete = new window.google.maps.places.Autocomplete(
				inputRef.current,
				{
					componentRestrictions: { country: ['us', 'ca'] },
					fields: ['address_components', 'geometry'],
					types: ['address'],
				}
			);
			autocomplete.addListener('place_changed', fillInAddress);
		} else {
			// helpers.setError('Address is not complete');
			// config.setErrorType('googlePlaces');
		}
	};

	useEffect(() => {
		initAutocomplete();
	}, []);

	// useEffect(() => {
	// 	if (field.value) {
	// 		setFocus('active');
	// 	}
	// 	if (!config.address && !meta.error) {
	// 		helpers.setError('Address is not complete');
	// 	}
	// });

	const clearField = () => {
		setValue(name, '');
		setFocus('active');
	};

	// const handleChange = (e) => {
	// 	helpers.setTouched(true, true);
	// 	field.onChange(e);
	// };

	const handleBlur = () => {
		if (!inputRef.current?.value) setFocus('');
		if (!watchField) setFocus('');
	};

	return (
		<>
			<div className="mb-2">
				<div className="input-outline">
					<input
						ref={(e) => {
							register(e);
							inputRef.current = e; // you can still assign to ref
						}}
						// ref={register(objectError())}
						onFocus={() => setFocus('active')}
						onBlur={handleBlur}
						name={name}
						type="text"
						className="form-control"
						id={id}
						readOnly={readOnly}
						placeholder=""
					/>

					<label className={focus} htmlFor={id}>
						{label}
						{required && <span className="text-danger"> *</span>}
					</label>
					<div className="action-buttons">
						{showClearButton && (
							<AiFillCloseCircle
								className="inside-buttonAction"
								title="Clear"
								onClick={clearField}
								onKeyPress={clearField}
							/>
						)}
					</div>
				</div>
				<ErrorMessage errors={errors} name={name}>
					{({ messages, message }) => {
						return (
							<>
								{message && (
									<p className="bg-danger px-3 text-center text-white">
										{message}
									</p>
								)}
								{messages &&
									Object.entries(messages).map(([type, msg]) => (
										<p
											className="bg-danger px-3 text-center text-white"
											key={type}
										>
											{msg}
										</p>
									))}
							</>
						);
					}}
				</ErrorMessage>
			</div>
		</>
	);
};

PlacesAutoComplete.propTypes = {
	name: PropTypes.string.isRequired,
};

export default PlacesAutoComplete;
