const ROUTES = {
	HOME: '/',
	BILL_NEGOTIATION: '/bill-negotiation',
	SUBSCRIPTION_CANCELLATION: '/subscription-cancellation',
	BILL_REVIEW: '/bill-review',
	PRIVACY_PROTECT: '/privacy-protect',
	PLATFORM: '/platform',
	USE_CASES: '/use-cases',
	TEAM: '/team',
	TERMS: '/terms',
	PRIVACY_POLICY: '/privacy-policy',
	LOGIN: '/login',
	LOGOUT: '/logout',
	PARTNER_PORTAL: '/partner-portal',
	DEVELOPER_SECTION: '/developer-section',
	SUPPORT: '/support',
	API: '/api/v3',
};

export default ROUTES;
