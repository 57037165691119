/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Alert } from 'reactstrap';
import queryString from 'query-string';

import Spinner from 'reactstrap/lib/Spinner';
import { navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import { getCfrToken, sendMissingData } from '../../../../utils/requestUtils';
import { DispatchContext, StateContext } from '../../../../../context';
import OutlineInput from '../../../../shared/FormField/OutlineInput';

const RetrieveToken = () => {
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	// const contentObject = JSON.parse(contentParser.getOnlyContent(content));

	useEffect(() => {
		const didMount = async () => {
			if (typeof window !== 'undefined') {
				const queryData = queryString.parse(window.location.search);
				const { hash } = queryData;
				dispatch({ type: 'setHash', payload: hash });
				if (/* contentObject && */ hash) {
					await getCfrToken({
						tokens: { 'x-api-key': 'tok_16219740844720612Lqw6fDlHpGb' },
						hash: window.location.search,
						dispatch,
					});
				}
			}
		};
		didMount();
	}, []);

	useEffect(() => {
		if (state.isSubmitted) {
			console.log('hash submitted');
			navigate('/success');
		}
	}, [state.isSubmitted]);

	const { register, errors, handleSubmit, watch, setValue, control } =
		useForm();
	// const { Http } = useReduxHandler();

	const handleOnSubmit = async (values) => {
		const response = state.fields.map((item) => {
			return {
				...item,
				answer: values[item.record_no] || '',
			};
		});

		await sendMissingData({
			tokens: { 'x-api-key': 'tok_16219740844720612Lqw6fDlHpGb' },
			cfrToken: state.cfrToken,
			requestObject: { hash: state.hash, fields: response },
			dispatch,
		});
	};
	return (
		<div className="token-wrapper">
			<div className="title text-dark">
				{state.loadingCfrToken && (
					<div
						className="d-flex justify-content-center align-items-center w-100 h-100"
						style={{ minHeight: 'calc(100vh - 370px)' }}
					>
						<Spinner color="light" style={{ height: '4rem', width: '4rem' }} />
					</div>
				)}
				{state.missingDataErrorMessage && (
					<div
						className="d-flex justify-content-center align-items-center w-100 h-100"
						style={{ minHeight: 'calc(100vh - 370px)' }}
					>
						<div className="rounded shadow p-4 d-flex flex-column bg-white">
							<h4 className="border-bottom pb-2 w-100">
								Submit Missing Information
							</h4>
							<div
								dangerouslySetInnerHTML={{
									__html: state.missingDataErrorMessage,
								}}
								className="text-error-inner text-xs pt-3"
							/>
						</div>
					</div>
				)}
				{state.notes && (
					<Alert color="primary">
						<div
							dangerouslySetInnerHTML={{
								__html: state.notes,
							}}
							className="text-inner-primary text-sm"
						/>
					</Alert>
				)}
			</div>
			<div className="form-fields-wrapper" style={{ width: '100%' }}>
				{state.fields.length > 0 && (
					<form onSubmit={handleSubmit(handleOnSubmit)} noValidate>
						{state.fields.map((item) => {
							return (
								<OutlineInput
									key={`${item.record_no}-${item.question}`}
									name={`${item.record_no}`}
									label={item.question}
									inputId={`${item.record_no}`}
									required
									register={register}
									errors={errors}
									watch={watch}
									setValue={setValue}
									defaultValues={{}}
									readOnly={item.readOnly}
									control={control}
									description={item.description}
									inputType="textarea"
									showinfoIcon
								/>
							);
						})}
						<button
							type="submit"
							className="btn btn-primary btn-send-response my-4 mx-auto"
							// disabled={isRecordLoading()}
						>
							Submit
						</button>
					</form>
				)}
			</div>
		</div>
	);
};

export default RetrieveToken;
