/* eslint-disable consistent-return */
/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactNotification from 'react-notifications-component';
import { StaticQuery, graphql } from 'gatsby';
import LayoutPublic from './LayoutPublic';
// import LayoutPrivate from './LayoutPrivate';
// import LayoutApiDocs from './LayoutApiDocs';
// import LayoutBlogPost from './LayoutBlogPost';

// const Layout = (props) => {
// 	const {
// 		type,
// 		pageInfo,
// 		header,
// 		viewMainSection,
// 		mainSection,
// 		classNameMain,
// 		viewSideBar,
// 		sidebarItems,
// 		names,
// 		menu,
// 		footerTopMenu,
// 		footerBottomMenu,
// 		path,
// 		fluid,
// 		children,
// 		slug,
// 		searchOptions,
// 		isLogged,
// 		currentApiId,
// 	} = props;
const Layout = (props) => {
	const {
		type,
		pageInfo,
		header,
		viewMainSection,
		mainSection,
		classNameMain,
		menu,
		footerTopMenu,
		footerBottomMenu,
		path,
		children,
		isLogged,
	} = props;

	return (
		<StaticQuery
			query={graphql`
				query SiteTitleQuery {
					site {
						siteMetadata {
							title
						}
					}
				}
			`}
			render={(data) => {
				return (
					<>
						<ReactNotification />
						{(() => {
							switch (type) {
								case 'public':
									return (
										<LayoutPublic
											title={data.site.siteMetadata.title}
											pageInfo={pageInfo}
											header={header}
											viewMainSection={viewMainSection}
											mainSection={mainSection}
											classNameMain={classNameMain}
											menu={menu}
											footerTopMenu={footerTopMenu}
											footerBottomMenu={footerBottomMenu}
											path={path}
											isLogged={isLogged}
										>
											{children}
										</LayoutPublic>
									);

								// case 'private':
								// 	return (
								// 		<LayoutPrivate
								// 			title={data.site.siteMetadata.title}
								// 			pageInfo={pageInfo}
								// 			header={header}
								// 			viewMainSection={viewMainSection}
								// 			mainSection={mainSection}
								// 			classNameMain={classNameMain}
								// 			viewSideBar={viewSideBar}
								// 			sidebarItems={sidebarItems}
								// 			names={names}
								// 			menu={menu}
								// 			footerTopMenu={footerTopMenu}
								// 			footerBottomMenu={footerBottomMenu}
								// 			path={path}
								// 			fluid={fluid}
								// 			slug={slug}
								// 			searchOptions={searchOptions}
								// 			isLogged={isLogged}
								// 		>
								// 			{children}
								// 		</LayoutPrivate>
								// 	);
								// case 'blogPost':
								// 	return (
								// 		<LayoutBlogPost
								// 			title={data.site.siteMetadata.title}
								// 			pageInfo={pageInfo}
								// 			header={header}
								// 			classNameMain={classNameMain}
								// 			menu={menu}
								// 			footerTopMenu={footerTopMenu}
								// 			footerBottomMenu={footerBottomMenu}
								// 			path={path}
								// 			slug={slug}
								// 			isLogged={isLogged}
								// 		>
								// 			{children}
								// 		</LayoutBlogPost>
								// 	);
								default:
								// return (
								// 	<LayoutApiDocs
								// 		title={data.site.siteMetadata.title}
								// 		pageInfo={pageInfo}
								// 		header={header}
								// 		viewMainSection={viewMainSection}
								// 		mainSection={mainSection}
								// 		classNameMain={classNameMain}
								// 		sidebarItems={sidebarItems}
								// 		names={names}
								// 		menu={menu}
								// 		footerTopMenu={footerTopMenu}
								// 		footerBottomMenu={footerBottomMenu}
								// 		path={path}
								// 		fluid={fluid}
								// 		isLogged={isLogged}
								// 		currentApiId={currentApiId}
								// 	>
								// 		{children}
								// 	</LayoutApiDocs>
								// );
							}
						})()}
					</>
				);
			}}
		/>
	);
};

Layout.propTypes = {
	header: PropTypes.string,
	mainSection: PropTypes.objectOf(PropTypes.any),
	viewMainSection: PropTypes.bool,
	// viewSideBar: PropTypes.bool,
	// sidebarItems: PropTypes.arrayOf(PropTypes.any),
	classNameMain: PropTypes.string,
};

Layout.defaultProps = {
	header: '',
	mainSection: {},
	viewMainSection: true,
	// viewSideBar: false,
	// sidebarItems: [],
	classNameMain: '',
};

export default Layout;
