import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { AiOutlineArrowRight } from 'react-icons/ai';
// import ReactPlayer from 'react-player/file';
import Title from '../../../shared/Title/Title';

// Render a YouTube video player

import image from '../../../../images/png/phone-02.png';
import Frame from '../../../shared/Frames';
import linkUtils from '../../../utils/linkUtils';

export default function MainSectionHomePage(props) {
	const { data } = props;
	const {
		classStyle,
		buttons,
		isRenderRow,
		renderRow,
		heroLeft,
		heroRight,
		heroSection,
	} = data;

	// const getUrlArray = (videoUrl) => {
	// 	const vUrl = [{ src: videoUrl, type: 'video/webm' }];
	// 	if (videoUrl.includes('.webm'))
	// 		vUrl.push({
	// 			src: videoUrl.replace('.webm', '.mp4'),
	// 			type: 'video/mp4',
	// 		});

	// 	return vUrl;
	// };
	const renderMediaContent = (imgUrl, videoUrl, classname, videoFrame) => {
		if (videoUrl) {
			return (
				<div className={classname}>
					<Frame videoFrame={videoFrame || ''}>
						{/* <ReactPlayer
							className="react-player-custom"
							url={getUrlArray(videoUrl)}
							// url="/videos/home_main_last.mp4"
							playing
							loop
							// playsinline
							volume={0}
							muted
							width="fit-content"
							height="fit-content"
							controls
							config={{
								attributes: {
									style: {
										width: '100%',
										height: 'auto',
									},
								},
							}}
						/> */}
					</Frame>
				</div>
			);
		}
		return <img src={imgUrl} alt="left cards" className={classname} />;
	};

	return (
		<div
			className="main-container"
			id={linkUtils.createLink(
				heroSection.sidebarTitle,
				heroSection.name,
				heroSection.title,
				heroSection.id
			)}
		>
			<div
				className={`mb-5 main-section-header ${classStyle && classStyle[0]}`}
			>
				<Row className="no-gutters">
					<Col md="7" xs="12" />
					<Col
						md="5"
						xs="12"
						className={
							classStyle && classStyle[2]
								? `left-section ${classStyle && classStyle[2]}`
								: 'left-section'
						}
					/>
				</Row>
			</div>
			<div className="main-middle padding-vertical">
				{!isRenderRow ? (
					<div className="container">
						<Row className="no-gutters">
							<Col lg="6" xs="12">
								{heroLeft.title && (
									<Title
										// className="mb-2 mb-md-5"
										title={heroLeft.title}
										classNameTitle={`text-left hero-display-4 ${heroLeft.className}`}
									/>
								)}
								{heroLeft.text && (
									<div key={heroLeft.text} className={`${heroLeft.className}`}>
										{heroLeft.text}
									</div>
								)}
								<div className="d-none d-md-flex mt-5">
									{buttons &&
										buttons.length > 0 &&
										buttons.map((item) => {
											const arrowIcon = item.text.includes('->');
											const text = item.text.replace('->', '');
											return (
												<div
													className="d-flex justify-content-center justify-content-md-end pr-md-4"
													key={item.id}
												>
													<button
														type="button"
														className={item.classname}
														onClick={item.onClick}
														style={{ width: '200px' }}
													>
														{text} {arrowIcon && <AiOutlineArrowRight />}
													</button>
												</div>
											);
										})}
								</div>
								{(heroLeft.image || heroLeft.video) &&
									renderMediaContent(
										heroLeft.image,
										heroLeft.video,
										'd-fex d-md-none mt-5 mt-md-5',
										heroLeft.videoFrame
									)}
							</Col>
							<Col
								lg="6"
								xs="12"
								className="d-flex flex-column d-md-flex mt-md-5 mt-lg-0 align-items-center"
							>
								{heroRight.title && (
									<Title
										// className="mb-2 mb-md-5"
										title={heroRight.title}
										classNameTitle={`text-left hero-display-4 ${heroRight.className}`}
									/>
								)}
								{heroRight.text && (
									<div
										key={heroRight.text}
										className={`${heroRight.className}`}
									>
										{heroRight.text}
									</div>
								)}
								{(heroRight.image || heroRight.video) &&
									renderMediaContent(
										heroRight.image,
										heroRight.video,
										`ml-lg-2 mt-lg-3 ${heroRight.className} ${
											heroRight.className && heroRight.className.includes('w-')
												? ''
												: 'w-100'
										}`,
										heroRight.videoFrame
									)}
							</Col>
						</Row>
					</div>
				) : (
					renderRow
				)}
			</div>
			<div
				className={`mb-5 main-section-header-back ${
					classStyle && classStyle[1]
				}`}
			/>
		</div>
	);
}

MainSectionHomePage.propTypes = {
	data: PropTypes.objectOf(PropTypes.any),
};

const title = {
	text: 'Super title',
	className: 'text-white',
};
const description = [
	{
		text: `Customers overpay hundreds per year for their Mobile, TV, Internet, Phone, Alarm, and others services. 
    Providers have cultivated this pricing surrender  and apathy through low service standards and complicated pricing. 
    We have solved this problem.`,
		className: 'text-white',
	},
	{
		text: `Integrating Bill Negotiation into your native UX levels the playing field. With minimal data, 
    we will negotiate lower monthly rates for your costumer without changing their service. Savings average $260 per bill!`,
		className: 'text-white',
	},
	{
		text: `Customers save money. You build loyalty and revenue.`,
		className: 'text-white',
	},
];

const mainImage = { src: image };

const classStyle = ['main-section-blue', 'main-section-back-blue'];

const buttons = [
	{
		text: 'Schedule a meeting',
		className: 'pr-4 d-none d-md-inline-block',
		color: 'primary',
	},
	{ text: 'Contact', className: 'd-none d-md-inline-block' },
];

const isRenderRow = false;
const renderRow = null;

MainSectionHomePage.defaultProps = {
	data: {
		title,
		description,
		buttons,
		mainImage,
		classStyle,
		isRenderRow,
		renderRow,
	},
};
