import React from 'react';
// import { Container, Row, Col } from 'reactstrap';
import { Col } from 'reactstrap';
import ColManager from '../ColManager';
import Connect from '../../../sections/Connect';
// import Tabs from '../../../sections/Tabs';
// import SeismicImpact from '../../../sections/SeismicImpact';
// import Carousel from '../../../sections/Carousel';
import RowContainer from '../../../sections/RowContainer';
// import FeaturedPosts from '../../../sections/FeaturedPosts';
// import Title from '../../../../shared/Title';
import { getTabSize } from '../../../../utils/functionUtils';
import Headings from '../../../../shared/Headings/Headings';

// export default function RowManager({
// 	row,
// 	isPrivate,
// 	slug,
// 	pagination,
// 	changeVisibility,
// 	posts = [],
// 	sectionId,
// }) {
export default function RowManager({
	row,
	isPrivate,
	slug,
	changeVisibility,
	sectionId,
}) {
	const getHeadingTitle = (sidebarLevel) => {
		switch (sidebarLevel) {
			case 3:
				return 'h5';
			case 4:
				return 'h6';

			default:
				return 'h4';
		}
	};

	let parent = null; // parent to create id of subsection
	let parentObjectOne = null; // subsection object
	let parentObjectTwo = null; // subsection object

	return (
		<>
			{row &&
				(() => {
					switch (row.type) {
						case 'CONNECT':
							return <Connect subSections={row.sub_sections} />;
						// case 'TABS':
						// 	return (
						// 		<Tabs
						// 			subSections={row.sub_sections}
						// 			tabSize={slug === 'home' ? 7 : 12}
						// 			tabOffset={slug === 'home' ? 5 : 0}
						// 		/>
						// 	);
						default:
							return (
								<RowContainer>
									{row.title && isPrivate && (
										<Col md="12" id={sectionId}>
											<Headings tag="h3">{row.title} </Headings>
										</Col>
									)}
									{row.sub_sections &&
										row.sub_sections.length > 0 &&
										row.sub_sections.map((col) => {
											const sidebarLevel = getTabSize(col.sidebarLevel);

											if (col.sidebarTitle) {
												if (sidebarLevel < 3) {
													parent = { sidebarTitle: row.sidebarTitle };
													parentObjectOne = col;
												} else if (sidebarLevel === 3 && parentObjectOne) {
													parent = {
														sidebarTitle: `${row.sidebarTitle}_${parentObjectOne.sidebarTitle}`,
													};
													parentObjectTwo = col;
												} else if (parentObjectOne && parentObjectTwo) {
													parent = {
														sidebarTitle: `${row.sidebarTitle}_${parentObjectOne.sidebarTitle}_${parentObjectTwo.sidebarTitle}`,
													};
												}
											}
											return (
												<ColManager
													key={col.id}
													col={col}
													isPrivate={isPrivate}
													slug={slug}
													parent={parent}
													headingTitle={getHeadingTitle(sidebarLevel)}
													changeVisibility={changeVisibility}
												/>
											);
										})}
								</RowContainer>
							);
					}
				})()}
		</>
	);
}
