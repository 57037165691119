/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const MultiSelect = ({ item, register, setValue }) => {
	const { minLength } = item;
	let initialState = {};
	if (minLength > 0) {
		let cont = 0;

		item.options.forEach((el) => {
			if (cont < minLength) {
				initialState = { ...initialState, [el.value]: true };
			}
			cont++;
		});
	}
	const [state, setState] = useState(initialState);

	const handleChange = (e) => {
		const { value, checked } = e.target;
		setState((prev) => {
			if (!checked) {
				const valuesChecked = Object.keys(prev).filter((el) => prev[el]);
				if (valuesChecked.length > minLength) {
					return { ...prev, [value]: checked };
				}
				return { ...prev, [value]: true };
			}

			return { ...prev, [value]: checked };
		});
	};

	useEffect(() => {
		let finalValue = [];
		Object.keys(state).forEach((el) => {
			if (state[el]) {
				finalValue = [...finalValue, el];
			}
		});

		setValue(item.name, JSON.stringify(finalValue));
	}, [state]);

	return (
		<>
			<input type="hidden" name={item.name} ref={register} />
			{item &&
				item.options &&
				item.options.length > 0 &&
				item.options.map((el) => {
					return (
						<div className="form-check" key={el.label}>
							<input
								name={el.label}
								type="checkbox"
								className="form-check-input"
								value={el.value}
								checked={state[el.value]}
								onChange={handleChange}
							/>
							<span className="form-check-label">{el.label}</span>
						</div>
					);
				})}
		</>
	);
};

MultiSelect.propTypes = {
	item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MultiSelect;
