/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import RandExp from 'randexp';
import { ErrorMessage } from '@hookform/error-message';
import {
	FaPenAlt,
	FaCheckDouble,
	FaRegCopy,
	FaInfoCircle,
} from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
	getInputType,
	// getInputStep,
} from '../../containers/templates/shared/ApiManager/util';
import FileInput from './FileInput';

const OutlineInput = ({
	register,
	name,
	required,
	maxLength,
	minLength,
	min,
	max,
	pattern,
	dataType,
	label,
	inputId,
	inputType,
	watch,
	errors,
	sample,
	setValue,
	defaultValues,
	validations,
	showClearButton,
	showCopyToClipboardButton = false,
	formatValue,
	readOnly,
	showinfoIcon,
	description,
}) => {
	const watchField = watch && watch(name);

	const [focus, setFocus] = useState('');
	const [copied, setCopied] = useState(false);

	const inputRef = useRef(null);
	const handleBlur = () => {
		if (!inputRef.current?.value) setFocus('');
		if (!watchField) setFocus('');
	};
	useEffect(() => {
		if (watchField) {
			setFocus('active');
			const value = formatValue(watchField);
			setValue(name, value);
		}
	}, [watchField]);
	useEffect(() => {
		if (defaultValues[name]) {
			setValue(name, defaultValues[name], { shouldValidate: true });
			setFocus('active');
		}
	}, [defaultValues[name]]);

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 1200);
		}
	}, [copied]);

	const id = inputId || `input-${label}`;

	const objectError = () => {
		let obj = {};
		if (validations) {
			obj = validations;
		}

		if (required) {
			if (name.includes('.')) {
				const parentName = name.split('.');
				parentName.pop();
				const watchParent = watch(parentName.join('.')) || {};
				const changeAnyChild = Object.keys(watchParent).some(
					(key) =>
						typeof watchParent[key] !== 'object' &&
						!Array.isArray(watchParent[key]) &&
						watchParent[key] &&
						watchParent[key] !== ''
				);
				if (changeAnyChild) {
					obj = { ...obj, required: 'This field is required' };
				} else {
					obj = { ...obj, required: false };
				}
			} else {
				obj = { ...obj, required: 'This field is required' };
			}
		} else {
			obj = { ...obj, required: false };
		}
		if (maxLength && maxLength > 0) {
			obj = {
				...obj,
				maxLength: {
					value: maxLength,
					message: `The maxLength is ${maxLength}`,
				},
			};
		} else {
			obj = {
				...obj,
				maxLength: {
					value: 1000,
					message: `The maxLength is ${1000}`,
				},
			};
		}
		if (minLength) {
			obj = {
				...obj,
				minLength: {
					value: minLength,
					message: `The maxLength is ${minLength}`,
				},
			};
		}
		if (min) {
			obj = {
				...obj,
				min: {
					value: min,
					message: `The min is ${min}`,
				},
			};
		}
		if (max) {
			obj = {
				...obj,
				max: {
					value: max,
					message: `The max is ${max}`,
				},
			};
		}
		if (pattern) {
			obj = {
				...obj,
				pattern: {
					value: pattern,
					message: `This field don't match with pattern`,
				},
			};
		}
		return obj;
	};

	const loadSampleData = async () => {
		const ranValue = await new RandExp(sample.regexp).gen();
		setValue(name, ranValue);
		setFocus('active');
	};

	const clearField = () => {
		setValue(name, '');
		setFocus('active');
	};
	if (inputType === 'file') {
		return (
			<FileInput
				register={register}
				name={name}
				label={label}
				required={required}
				setValue={setValue}
			/>
		);
	}

	return (
		<div className="mb-4 pb-3">
			<div className="input-outline">
				{inputType === 'text' ? (
					<input
						ref={(e) => {
							register(e, objectError());
							inputRef.current = e; // you can still assign to ref
						}}
						// ref={register(objectError())}
						onFocus={() => setFocus('active')}
						onBlur={handleBlur}
						name={name}
						type={getInputType(dataType)}
						className="form-control"
						id={id}
						readOnly={readOnly}
					/>
				) : (
					<textarea
						ref={(e) => {
							register(e, objectError());
							inputRef.current = e; // you can still assign to ref
						}}
						onFocus={() => setFocus('active')}
						onBlur={handleBlur}
						name={name}
						defaultValue=""
						className="form-control"
						id={id}
					/>
				)}
				<label className={focus} htmlFor={id}>
					{label}
					{required && <span className="text-danger"> *</span>}
				</label>
				<div className="action-buttons">
					{sample && (
						<FaPenAlt
							className="inside-buttonAction"
							title="Fill with sample data"
							onClick={loadSampleData}
							onKeyPress={loadSampleData}
						/>
					)}

					{showClearButton && (
						<AiFillCloseCircle
							className="inside-buttonAction"
							title="Clear"
							onClick={clearField}
							onKeyPress={clearField}
						/>
					)}
					{showCopyToClipboardButton && (
						<CopyToClipboard text={watchField} onCopy={() => setCopied(true)}>
							<button
								type="button"
								className="btn-copy px-3"
								title="Copy to Clipboard"
							>
								{copied ? (
									<FaCheckDouble color="green" />
								) : (
									<FaRegCopy color="#4E4E4E" />
								)}
							</button>
						</CopyToClipboard>
					)}
					{showinfoIcon && description && (
						<FaInfoCircle className="inside-buttonAction" title={description} />
					)}
				</div>
			</div>
			<ErrorMessage errors={errors} name={name}>
				{({ messages, message }) => {
					return (
						<>
							{message && (
								<p className="bg-danger px-3 text-center text-white">
									{message}
								</p>
							)}
							{messages &&
								Object.entries(messages).map(([type, msg]) => (
									<p
										className="bg-danger px-3 text-center text-white"
										key={type}
									>
										{msg}
									</p>
								))}
						</>
					);
				}}
			</ErrorMessage>
		</div>
	);
};

OutlineInput.propTypes = {
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	dataType: PropTypes.string,
	label: PropTypes.string,
	errors: PropTypes.objectOf(PropTypes.any),
	inputType: PropTypes.string,
	showClearButton: PropTypes.bool,
	formatValue: PropTypes.func,
	showinfoIcon: PropTypes.bool,
};

OutlineInput.defaultProps = {
	required: false,
	dataType: 'STRING',
	label: '',
	errors: {},
	inputType: 'text',
	showClearButton: true,
	formatValue: (e) => e,
	showinfoIcon: false,
};

export default OutlineInput;
