/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';

class SectionContainer extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			children,
			className: classname,
			id,
			bgColor,
			jsonversion,
		} = this.props;

		return (
			<section
				className={classname}
				id={id}
				style={{ backgroundColor: bgColor }}
				data-jsonversion={jsonversion}
			>
				{children}
			</section>
		);
	}
}

SectionContainer.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	// eslint-disable-next-line react/no-unused-prop-types
	isPrivate: PropTypes.bool,
};

SectionContainer.defaultProps = {
	className: '',
	id: null,
	isPrivate: false,
};

export default SectionContainer;
