/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Nav, NavItem } from 'reactstrap';
import {
	FaRegCopy,
	FaLink,
	FaCheckDouble,
	FaCompress,
	FaExpand,
	FaCheck,
	FaRadiationAlt,
} from 'react-icons/fa';

import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import plaintext from 'react-syntax-highlighter/dist/esm/languages/hljs/plaintext';
// import typescript from 'react-syntax-highlighter/dist/esm/languages/hljs/typescript';
// import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
// import css from 'react-syntax-highlighter/dist/esm/languages/hljs/css';
import {
	vs2015,
	a11yLight,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { navigate } from '@reach/router';

SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('plaintext', plaintext);
// SyntaxHighlighter.registerLanguage('typescript', typescript);
// SyntaxHighlighter.registerLanguage('xml', xml);
// SyntaxHighlighter.registerLanguage('css', css);

const EditorWidget = ({
	code,
	codeTitle,
	codeBgColor,
	className,
	apiId,
	codeLanguage = -1,
	responseHeight = false,
	validate = false,
	validateFunction = () => {},
	hasValidationError = false,
}) => {
	const [copied, setCopied] = useState(false);
	const [maximize, setMaximize] = useState(false);
	const [finalCode, setFinalCode] = useState('');
	const [showCode, setShowCode] = useState(false);

	const toggleShowCode = () => {
		setShowCode(!showCode);
	};

	useEffect(() => {
		let codeToShow = code || '';
		if (code && code.includes('<pre><code')) {
			codeToShow = code && code.match(/<pre><code [^>]+>(.*)<\/code><\/pre>/s);
		}
		if (!showCode) {
			if (Array.isArray(codeToShow)) {
				setFinalCode(codeToShow[1].substring(0, 350));
			} else {
				setFinalCode(codeToShow.substring(0, 350));
			}
		} else if (Array.isArray(codeToShow)) {
			setFinalCode(codeToShow[1]);
		} else {
			setFinalCode(codeToShow);
		}
		return () => setFinalCode(null);
	}, [showCode]);

	let codeLang = 'json';
	if (codeLanguage !== -1) {
		codeLang = codeLanguage;
	} else {
		codeLang = code && code.match(/<code class="language-(.*?)">/);

		if (Array.isArray(codeLang)) codeLang = codeLang[1];
	}

	function changeCopied() {
		setCopied(true);
		setTimeout(() => setCopied(false), 5000);
	}
	const capitalize = (s) => {
		if (typeof s !== 'string') return false;
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	const getCodeHeigth = () => {
		if (responseHeight) {
			return maximize ? '91vh' : responseHeight;
		}
		return maximize ? '91vh' : '250px';
	};
	return (
		<div
			className={`${className || ''}  ${
				maximize ? 'editor-container-maximized' : 'editor-container'
			}`}
		>
			<div className={`code-box ${hasValidationError ? 'has-error' : ''}`}>
				<div
					className="copy-container"
					style={{
						background: `${codeBgColor === 'dark' ? '#4f566b' : '#E3E8EE'}`,
					}}
				>
					<Nav pills>
						<NavItem
							className={`${
								codeBgColor === 'dark' ? 'code-title-dark' : 'code-title-light'
							}`}
						>
							{codeTitle || capitalize(codeLang) || capitalize('Json')}
							{hasValidationError && (
								<FaRadiationAlt color="red" className="ml-2" />
							)}
						</NavItem>
					</Nav>
					<div className="code-buttons-wrapper">
						{apiId && (
							<div className="ml-auto">
								<button
									type="button"
									className="btn-copy"
									title="Link"
									onClick={() => navigate(`api#${apiId}`)}
								>
									<FaLink
										color={`${codeBgColor === 'dark' ? '#c1c9d2' : '#4E4E4E'}`}
									/>
								</button>
							</div>
						)}

						<CopyToClipboard text={finalCode} onCopy={changeCopied}>
							<button
								type="button"
								className="btn-copy"
								title="Copy to Clipboard"
							>
								{copied ? (
									<FaCheckDouble color="green" />
								) : (
									<FaRegCopy
										color={`${codeBgColor === 'dark' ? '#c1c9d2' : '#4E4E4E'}`}
									/>
								)}
							</button>
						</CopyToClipboard>
						<button
							className="code-viewer-maximize btn-copy"
							type="button"
							name="Maximize"
							onClick={() => setMaximize(!maximize)}
						>
							{maximize ? (
								<FaCompress
									color={`${codeBgColor === 'dark' ? '#c1c9d2' : '#4E4E4E'}`}
								/>
							) : (
								<FaExpand
									color={`${codeBgColor === 'dark' ? '#c1c9d2' : '#4E4E4E'}`}
								/>
							)}
						</button>
						{validate && (
							<button
								className="code-viewer-validate btn-copy"
								type="button"
								name="Validate"
								onClick={() => validateFunction()}
							>
								<FaCheck
									color={`${codeBgColor === 'dark' ? '#c1c9d2' : '#4E4E4E'}`}
								/>
							</button>
						)}
					</div>
				</div>
				<div className="code-wrapper">
					<SyntaxHighlighter
						language={codeLang || 'javascript'}
						style={codeBgColor === 'dark' ? vs2015 : a11yLight}
						showLineNumbers
						customStyle={{
							overflowX: 'auto',
							background: `${codeBgColor === 'dark' ? '#4f566b' : '#fff'}`,
							padding: '15px',
							overflowY: 'auto',
							maxHeight: `${getCodeHeigth()}`,
							border: `${
								codeBgColor === 'dark' ? 'none' : '1px solid #E3E8EE'
							}`,
						}}
					>
						{finalCode}
					</SyntaxHighlighter>
					{finalCode && finalCode.length === 350 && (
						<button
							className={
								codeBgColor === 'dark'
									? 'btn-show-all-code-light'
									: 'btn-show-all-code-dark'
							}
							type="button"
							name="showCode"
							disabled={showCode}
							onClick={() => toggleShowCode()}
						>
							{showCode ? 'Loading...' : 'Load more...'}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

EditorWidget.propTypes = {
	code: PropTypes.string,
};

EditorWidget.defaultProps = {
	code: '',
};

export default EditorWidget;
