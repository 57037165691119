/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';

const ModalWidget = ({ isOpen, toggle, className, style, size, children }) => {
	return (
		<>
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				className={className}
				style={style}
				size={size}
			>
				<ModalHeader toggle={toggle} className="modal-custom-header" />
				<ModalBody>
					<Container>
						<Row>
							<Col>{children}</Col>
						</Row>
					</Container>
				</ModalBody>
			</Modal>
		</>
	);
};

ModalWidget.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	className: PropTypes.string,
	style: PropTypes.objectOf(PropTypes.any),
	size: PropTypes.string,
};

ModalWidget.defaultProps = {
	className: '',
	style: null,
	size: '',
};

export default ModalWidget;
