/* eslint-disable import/prefer-default-export */
export const getDefaultHeaders = ({ tokens, csfrToken }) => {
	if (!csfrToken) {
		return {
			'content-Type': 'application/json',
			'x-api-key': tokens && tokens['x-api-key'],
			'x-timezone-offset': new Date().getTimezoneOffset(),
		};
	}
	return {
		'content-Type': 'application/json',
		'x-api-key': tokens && tokens['x-api-key'],
		'x-timezone-offset': new Date().getTimezoneOffset(),
		csrf_token: csfrToken,
	};
};

export const sendConnectData = async ({
	tokens,
	cfrToken,
	requestObject,
	dispatch,
}) => {
	const requestOptions = {
		method: 'POST',
		headers: getDefaultHeaders({
			tokens,
			csfrToken: cfrToken,
		}),
		redirect: 'follow',
		body: JSON.stringify({
			...requestObject,
		}),
	};

	try {
		const response = await fetch(
			`https://gateway.billshark.com/retail-v3/partner/lead/ispdisconnect`,
			requestOptions
		);
		const result = await response.json();
		if (result.csrf_token) {
			dispatch({ type: 'setCfrToken', payload: result.csrf_token });
			if (result.error_message) {
				dispatch({
					type: 'setMissingDataErrorMessage',
					payload: result.error_message,
				});
			} else {
				dispatch({
					type: 'setIsSubmitted',
					payload: true,
				});
			}
		}
	} catch (err) {
		console.log(
			'🚀 ~ file: index.jsx ~ line 124 ~ getSecureMissingData ~ err',
			err.message
		);
	}
};
export const sendMissingData = async ({
	tokens,
	cfrToken,
	requestObject,
	dispatch,
}) => {
	const requestOptions = {
		method: 'POST',
		headers: getDefaultHeaders({
			tokens,
			csfrToken: cfrToken,
		}),
		redirect: 'follow',
		body: JSON.stringify({
			...requestObject,
		}),
	};

	try {
		const response = await fetch(
			`https://gateway.billshark.com/retail-v3/secure/missing`,
			requestOptions
		);
		const result = await response.json();
		console.log(
			'🚀 ~ file: requestUtils.js ~ line 38 ~ sendMissingData ~ result',
			result
		);
		if (result.csrf_token) {
			dispatch({ type: 'setCfrToken', payload: result.csrf_token });
			if (result.error_message) {
				dispatch({
					type: 'setMissingDataErrorMessage',
					payload: result.error_message,
				});
			} else {
				dispatch({
					type: 'setIsSubmitted',
					payload: true,
				});
			}
		}
	} catch (err) {
		console.log(
			'🚀 ~ file: index.jsx ~ line 124 ~ getSecureMissingData ~ err',
			err.message
		);
	}
};

export const getSecureMissingData = async ({
	tokens,
	hash,
	cfrToken,
	dispatch,
}) => {
	const requestOptions = {
		method: 'GET',
		headers: getDefaultHeaders({
			tokens,
			csfrToken: cfrToken,
		}),
		redirect: 'follow',
	};

	try {
		const response = await fetch(
			`https://gateway.billshark.com/retail-v3/secure/missing${hash}`,
			requestOptions
		);
		const result = await response.json();
		if (result.csrf_token) {
			dispatch({ type: 'setCfrToken', payload: result.csrf_token });
			if (result.error_message) {
				dispatch({
					type: 'setMissingDataErrorMessage',
					payload: result.error_message,
				});
			} else {
				dispatch({
					type: 'setNotesAndFields',
					payload: {
						notes: result.notes,
						fields: result.fields,
					},
				});
			}
		}
	} catch (err) {
		console.log(
			'🚀 ~ file: index.jsx ~ line 124 ~ getSecureMissingData ~ err',
			err.message
		);
	}
};

export const getCfrToken = async ({ tokens, hash, dispatch }) => {
	dispatch({ type: 'setLoadingCfrToken', payload: true });
	const requestOptions = {
		method: 'GET',
		headers: getDefaultHeaders({
			tokens,
		}),
		redirect: 'follow',
	};

	try {
		const response = await fetch(
			`https://gateway.billshark.com/retail-v3/csrf`,
			requestOptions
		);
		const result = await response.json();
		if (result.csrf_token) {
			dispatch({ type: 'setCfrToken', payload: result.csrf_token });
			if (hash) {
				await getSecureMissingData({
					tokens,
					cfrToken: result.csrf_token,
					hash,
					dispatch,
				});
			}
		}
		dispatch({ type: 'setLoadingCfrToken', payload: false });
	} catch (err) {
		console.log(
			'🚀 ~ file: index.jsx ~ line 124 ~ getCfrToken ~ err',
			err.message
		);
		dispatch({ type: 'setLoadingCfrToken', payload: false });
	}
};
