/* eslint-disable camelcase */
// import React, { useState } from 'react';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import ColType from './ColType';
import ColContainer from '../../../sections/ColContainer';
import linkUtils from '../../../../utils/linkUtils';
import Frame from '../../../../shared/Frames';
// import CodeType from './CodeType';
// import MemberCardType from './MemberCardType';
// import IframeType from './IframeType';
// import PDFType from './PDFType';
// import DataTableType from './DataTableType';
import RetrieveToken from './TOKENType';
import FormType from './FormType';
import CTAManager from '../CTAManager/CTAManager';

export default function ColManager({
	col,
	isPrivate,
	slug,
	parent,
	headingTitle,
	// changeVisibility,
}) {
	const {
		content,
		title,
		imgUrl,
		video_url,
		videoFrame,
		type,
		classname,
		columnDistribution,
		// codeLanguage,
		// apiId,
		order,
		// memberCardImgUrl,
		// memberPosition,
		// initialContent,
	} = col;

	// const [dataVisibility, setDataVisibility] = useState(null);

	// const getDataVisibility = (id, isVisible) => {
	// 	setDataVisibility({ id, isVisible });
	// };

	const renderData = (
		<>
			{type && type === 'COL' && (
				<ColType
					title={title}
					imgUrl={imgUrl}
					videoUrl={video_url}
					content={content}
					isPrivate={isPrivate}
					className={classname}
					headingTitle={headingTitle}
				/>
			)}
			{/* {type && type === 'CODE' && (
				<CodeType
					title={title}
					content={content}
					codeLanguage={codeLanguage}
					apiId={apiId}
				/>
			)} */}
			{/* {type && type === 'MEMBERCARD' && (
				<MemberCardType
					title={title}
					memberCardImgUrl={memberCardImgUrl}
					memberPosition={memberPosition}
					initialContent={initialContent}
					imgUrl={imgUrl}
					content={content}
				/>
			)} */}
			{/* {type && type === 'IFRAME' && (
				<IframeType content={content} title={title} />
			)} */}
			{/* {type && type === 'PDF' && (
				<PDFType
					imgUrl={imgUrl}
					title={title}
					dataVisibility={dataVisibility}
				/>
			)} */}
			{/* {type && type === 'CSV' && (
				<DataTableType
					imgUrl={imgUrl}
					title={title}
					dataVisibility={dataVisibility}
				/>
			)} */}
			{type && type === 'TOKEN' && (
				<RetrieveToken imgUrl={imgUrl} title={title} content={content} />
			)}
			{type && type === 'FORM' && <FormType content={content} />}
			<CTAManager ctas={col.ctas} />
		</>
	);
	return (
		<>
			<ColContainer
				className={` ${classname} ${
					isPrivate ? 'sub-section-private' : 'sub-section-public1'
				} ${isMobileOnly && (imgUrl || video_url) ? 'order-12' : 'order-0'}`}
				xs={12}
				md={columnDistribution}
				id={
					col.sidebarTitle
						? linkUtils.createLink(
								col.sidebarTitle,
								col.name,
								col.title,
								col.id,
								parent
						  )
						: null
				}
				isPrivate={isPrivate}
				backendName={col.name}
				order={order}
				slug={slug}
				// changeVisibility={getDataVisibility}
				sidebarTitle={col.sidebarTitle}
				type={type}
			>
				<Frame videoFrame={videoFrame}>{renderData}</Frame>
			</ColContainer>
		</>
	);
}
