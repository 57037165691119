/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
	Nav,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import { isMobileOnly } from 'react-device-detect';
import GatsbyImageItem from '../../GatsbyImageItem';
import MediaWidget from '../Media/MediaWidget';
import GatsbyNavItem from '../../GatsbyNavItem';
import { getItemUrl } from '../../utils/linkUtils';

function NavPublic(props) {
	const { color, menu, path } = props;

	const classNameText = color === 'dark' ? 'text-white' : '';

	const handleClick = (item) => {
		if (item.text === 'Connect') {
			// toggleOpenContactModal();
		}
	};

	return (
		<>
			<Nav className="public-navbar" navbar>
				{menu &&
					menu.length > 0 &&
					menu.map((item) => {
						const itemUrl = getItemUrl(item);
						const activeClassname = itemUrl === path;

						return (
							<React.Fragment key={`${item.id}`}>
								{item.menu_items.length > 0 ? (
									<UncontrolledDropdown
										className="mb-2 mb-lg-0 mr-0 mr-lg-4"
										nav
										inNavbar
									>
										<DropdownToggle nav caret={!isMobileOnly} color="#111">
											<span
												className={`text-s ${classNameText} ${
													isMobileOnly ? 'text-muted' : ''
												}`}
											>
												{item.text}
											</span>
										</DropdownToggle>
										<DropdownMenu
											left="true"
											tag="ul"
											className={`${
												isMobileOnly ? 'show border-0' : 'p-4 fr-dropdown'
											}`}
										>
											{item.menu_items.map((dropItem) => {
												const dropItemUrl = getItemUrl(dropItem);
												return (
													<GatsbyImageItem
														href={dropItemUrl}
														key={dropItem.text}
														className={`${
															dropItemUrl !== '/' && dropItemUrl === path
																? 'drop-item-active'
																: ''
														}`}
													>
														<MediaWidget
															classNameMedia="font-weight-bold"
															title={dropItem.text}
															subtitle={dropItem.subtitle}
															src={dropItem.imgUrl}
														/>
													</GatsbyImageItem>
												);
											})}
										</DropdownMenu>
									</UncontrolledDropdown>
								) : (
									<GatsbyNavItem
										className={`mb-2 mb-lg-0 mr-0 mr-lg-4 w-100 w-md-auto text-center ${
											item.classname
										} ${activeClassname && 'active'}`}
										href={itemUrl}
										onClick={() => handleClick(item)}
									>
										<span
											className={`${
												item.classname && item.classname.includes('btn')
													? ''
													: classNameText
											}`}
										>
											{item.text}
										</span>
									</GatsbyNavItem>
								)}
							</React.Fragment>
						);
					})}
			</Nav>
		</>
	);
}

NavPublic.propTypes = {
	color: PropTypes.string,
};

NavPublic.defaultProps = {
	color: '',
};

export default NavPublic;
