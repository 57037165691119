/* eslint-disable prefer-destructuring */
import dayjs from 'dayjs';

const fixUrl = (url, queryData = [], pathData = []) => {
	let finalUrl = url;
	if (finalUrl.includes('connect.')) {
		finalUrl = finalUrl.replace('connect.', 'sandbox.');
	}
	if (pathData.length > 0) {
		if (finalUrl.includes('<')) {
			pathData.forEach((el) => {
				const text = `<${el.key}>`;
				finalUrl = finalUrl.replace(text, el.value);
			});
		}
	}
	if (finalUrl.includes('?')) {
		finalUrl = finalUrl.split('?')[0];
		if (queryData.length > 0) {
			finalUrl = `${finalUrl}?`;
			let firstAmp = false;
			let queryP = '';
			queryData.forEach((el) => {
				queryP = `${queryP}${firstAmp ? '&' : ''}${el.key}=${el.value}`;
				firstAmp = true;
			});
			finalUrl = `${finalUrl}${queryP}`;
		}
	}

	return finalUrl;
};

function getRandomInt(min, max) {
	const minA = Math.ceil(min);
	const maxA = Math.floor(max);
	return Math.floor(Math.random() * (maxA - minA + 1)) + minA;
}

const getRandomString = (len) =>
	[...Array(len)]
		.map(() => Math.floor(Math.random() * 36).toString(36))
		.join('');

const getExactNumber = (len) => {
	let result = '';
	[...new Array(len)].forEach(() => {
		result += getRandomInt(0, 9);
	});
	return result;
};

const randomDate = (start, end) => {
	return new Date(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	);
};

const ramdomContactNumber = () => {
	return `+1${getRandomInt(1000000000, 9999999999)}`;
};

const getValueToItemInSampleData = (item) => {
	if (item.valueSample) {
		if (item.valueSample.value === '') {
			switch (item.valueSample.type) {
				case 'EMAIL':
					return `${getRandomString(getRandomInt(6, 14))}@gmail.com`;
				case 'COUNTRY':
					return getRandomString(2).toUpperCase();
				case 'ZIPCODE':
					return getRandomInt(10000, 99999);
				case 'EXACTNUMBER':
					return getExactNumber(item.dataLength);
				case 'CONTACTNUMBER':
					return ramdomContactNumber();
				case 'DOB':
					return dayjs(randomDate(new Date('1960-01-01'), new Date())).format(
						'YYYY-MM-DD'
					);
				default:
					return getRandomString(
						getRandomInt(item.dataLength / 2, item.dataLength)
					);
			}
		} else {
			return item.valueSample.value;
		}
	}
	return '';
};

const getInputType = (dataType) => {
	switch (dataType.toLowerCase()) {
		case 'string':
			return 'text';
		case 'float':
			return 'number';
		case 'integer':
			return 'number';
		case 'password':
			return 'password';
		case 'tel':
			return 'tel';
		default:
			return 'text';
	}
};
const getInputStep = (dataType) => {
	switch (dataType.toLowerCase()) {
		case 'string':
			return null;
		case 'float':
			return '0.01';
		case 'integer':
			return '1';
		case 'password':
			return null;
		case 'tel':
			return null;
		default:
			return null;
	}
};

const getInputSchemaType = (dataType) => {
	switch (dataType) {
		case 'LIST':
			return 'array';
		case 'Float':
			return 'number';
		default:
			return 'string';
	}
};

const getBgColor = (bgColor) => {
	let bgcolor = '';
	switch (bgColor) {
		case 'superLigthGray':
			bgcolor = '#F4F7FA';
			break;
		case 'LigthGray':
			bgcolor = '#EBF0F4';
			break;
		default:
			bgcolor = '#FFFFFF';
			break;
	}

	return bgcolor;
};

export {
	fixUrl,
	getValueToItemInSampleData,
	getInputType,
	getInputStep,
	getInputSchemaType,
	getBgColor,
	getRandomInt,
};
