/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';

const CheckBoxInput = ({
	name,
	required,
	label,
	register,
	errors,
	setValue,
	defaultValues,
}) => {
	useEffect(() => {
		if (defaultValues[name]) {
			setValue(name, defaultValues[name]);
		}
	}, [defaultValues[name]]);
	const objectError = () => {
		let obj = {};
		if (required) {
			obj = { ...obj, required: 'This field is required' };
		}
		return obj;
	};

	return (
		<div className="mb-2">
			<div className="form-check h-100" style={{ marginTop: '10px' }}>
				<input
					name={name}
					type="checkbox"
					className="form-check-input"
					ref={register(objectError())}
				/>
				<span className="form-check-label">
					{label}
					{required && <span className="text-danger"> *</span>}
				</span>
			</div>
			<ErrorMessage errors={errors} name={name}>
				{({ messages, message }) => {
					return (
						<>
							{message && (
								<p className="bg-danger px-3 text-center text-white">
									{message}
								</p>
							)}
							{messages &&
								Object.entries(messages).map(([type, msg]) => (
									<p
										className="bg-danger px-3 text-center text-white"
										key={type}
									>
										{msg}
									</p>
								))}
						</>
					);
				}}
			</ErrorMessage>
		</div>
	);
};

CheckBoxInput.propTypes = {
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	label: PropTypes.string,
	errors: PropTypes.objectOf(PropTypes.any),
};

CheckBoxInput.defaultProps = {
	required: false,
	label: '',
	errors: {},
};

export default CheckBoxInput;
