/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

class RowContainer extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { children, className, id } = this.props;
		return (
			<Row className={className} id={id}>
				{children}
			</Row>
		);
	}
}

RowContainer.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	// eslint-disable-next-line react/no-unused-prop-types
	isPrivate: PropTypes.bool,
};

RowContainer.defaultProps = {
	className: '',
	id: null,
	isPrivate: false,
};

export default RowContainer;
