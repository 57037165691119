/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
import React, { useReducer } from 'react';
import { Container } from 'reactstrap';
import SEO from '../../../seo';
import Layout from '../../../shared/Layout';
import RowManager from '../shared/RowManager';
import SectionContainer from '../../sections/SectionContainer';
import linkUtils from '../../../utils/linkUtils';
import contentParser from '../../../utils/contentParser';
import { DispatchContext, StateContext } from '../../../../context';
import globalReducer, { initialState } from '../../../../reducer/globalReducer';

export default function PublicTemplate({ pageContext }) {
	const {
		pathname: path,
		slug,
		title,
		description,
		keywords,
		sections,
		styles,
		headerMenus,
		footerTopMenus,
		footerBottomMenus,
		heroVariant,
	} = pageContext;

	const [state, dispatch] = useReducer(globalReducer, initialState);

	const viewMainSection = heroVariant !== null;

	const sectionContent = sections.filter((section) => section.type !== 'HERO');

	function getSectionStyle(variant) {
		switch (variant) {
			case 'SuperDarkBlue':
				return ['main-section-blue', 'main-section-back-blue'];
			case 'BLUE_LIGHT':
				return ['main-section-blue-light', 'main-section-back-blue-light'];
			case 'GRAY':
				return ['main-section-gray', 'main-section-back-gray'];
			case 'GREEN':
				return ['main-section-green', 'main-section-back-green'];
			case 'PURPLE':
				return ['main-section-purple', 'main-section-back-purple'];
			case 'RED':
				return ['main-section-red', 'main-section-back-red'];
			case 'Gradient45LightBlueLR':
				return [
					'main-section-Gradient45LightBlueLR',
					'main-section-back-Gradient45LightBlueLR',
				];
			case 'Gradient45GreenLR':
				return [
					'main-section-Gradient45GreenLR',
					'main-section-back-Gradient45GreenLR',
				];
			case 'Gradient90GrayTB':
				return [
					'main-section-Gradient90GrayTB',
					'main-section-back-Gradient90GrayTB',
				];
			case 'Gradient45DarkBlueLR':
				return [
					'main-section-Gradient45DarkBlueLR',
					'main-section-back-Gradient45DarkBlueLR',
				];
			case 'orangeLigthingBolt':
				return [
					'main-section-orangeLigthingBolt',
					'main-section-back-orangeLigthingBolt',
				];
			case 'pinkLight':
				return ['main-section-pinkLight', 'main-section-back-pinkLight'];
			default:
				return ['main-section-blue', 'main-section-back-blue'];
		}
	}

	function getHeroData() {
		if (!viewMainSection) return null;
		const heroSection = sections.filter((section) => section.type === 'HERO');
		const heroLeftSide =
			heroSection &&
			heroSection.length === 1 &&
			heroSection[0].sub_sections.filter((item) => item.type === 'HEROLEFT');
		const heroRightSide =
			heroSection &&
			heroSection.length === 1 &&
			heroSection[0].sub_sections.filter((item) => item.type === 'HERORIGHT');

		const heroLeft =
			heroLeftSide && heroLeftSide.length === 1
				? {
						title: heroLeftSide && heroLeftSide[0] && heroLeftSide[0].title,
						text: ReactHtmlParser(heroLeftSide[0].content),
						image: heroLeftSide[0].imgUrl,
						video: heroLeftSide[0].video_url,
						videoFrame: heroLeftSide[0].videoFrame,
						className: heroLeftSide[0].classname,
				  }
				: null;

		const heroRight =
			heroRightSide && heroRightSide.length === 1
				? {
						title: heroRightSide[0].title,
						text: ReactHtmlParser(heroRightSide[0].content),
						image: heroRightSide[0].imgUrl,
						video: heroRightSide[0].video_url,
						videoFrame: heroRightSide[0].videoFrame,
						className: heroRightSide[0].classname,
				  }
				: null;

		const buttons =
			heroLeftSide &&
			heroLeftSide.length === 1 &&
			heroLeftSide[0].ctas &&
			heroLeftSide[0].ctas.map((cta) => {
				return {
					...cta,
					onClick: () => onClick(cta.type),
				};
			});

		const classStyle = getSectionStyle(heroVariant);

		return {
			heroLeft,
			heroRight,
			classStyle,
			buttons,
			heroSection: heroSection[0],
		};
	}

	function getheaderColor(variant) {
		switch (variant) {
			case 'GRAY':
			case 'Gradient90GrayTB':
			case null:
				return 'dark';
			default:
				return '';
		}
	}
	const color = getheaderColor(heroVariant);

	return (
		<DispatchContext.Provider value={dispatch}>
			<StateContext.Provider value={state}>
				<Layout
					isPrivate={false}
					isLogged={false}
					type="public"
					viewMainSection={viewMainSection}
					mainSection={getHeroData()}
					header={color}
					menu={headerMenus}
					footerTopMenu={footerTopMenus}
					footerBottomMenu={footerBottomMenus}
					path={path}
					slug={slug}
				>
					<SEO
						title={title}
						description={description}
						pathname={path}
						cssStyle={styles && contentParser.getOnlyContent(styles)}
						keywords={keywords}
					/>
					{sectionContent &&
						sectionContent.length > 0 &&
						sectionContent.map((section, index) => {
							return (
								<SectionContainer
									key={`${section.id}`}
									id={linkUtils.createLink(
										section.sidebarTitle,
										section.name,
										section.title,
										section.id
									)}
									className={`public-section ${slug}-section ${
										section.classname || ''
									} ${index === 0 ? 'first-section' : ''}`}
									// bgColor={getBgColor(section.bgColor)}
								>
									<Container>
										<RowManager
											key={section.id}
											row={section}
											isPrivate={false}
											slug={slug}
											changeVisibility={() => {}}
										/>
									</Container>
								</SectionContainer>
							);
						})}
				</Layout>
			</StateContext.Provider>
		</DispatchContext.Provider>
	);
}
