/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { ColType } from '../../templates/shared/ColManager';
import CTAManager from '../../templates/shared/CTAManager';

function Connect(props) {
	const { className, subSections } = props;
	return (
		<>
			<Row
				style={{ paddingTop: '70px', paddingBottom: '70px' }}
				className={className}
				noGutters
			>
				{subSections &&
					subSections.length > 0 &&
					subSections.map((item) => {
						return (
							<React.Fragment key={item.id}>
								<Col
									className={`${item.classname}`}
									xs={12}
									md={item.columnDistribution}
									id={
										item.sidebarTitle && item.sidebarTitle.replace(/\s+/g, '_')
									}
								>
									<ColType
										title={item.title}
										content={item.content}
										headingTitle="h2"
									/>
								</Col>
								<Col xs="12" md="12">
									<CTAManager ctas={item.ctas} />
								</Col>
							</React.Fragment>
						);
					})}
			</Row>
		</>
	);
}

Connect.propTypes = {
	className: PropTypes.string,
};

Connect.defaultProps = {
	className: 'text-center',
};

export default Connect;
