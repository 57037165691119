import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
// eslint-disable-next-line import/no-cycle
import FormField from './FormField';
// import uuid from 'react-uuid';
// import OutlineInput from './OutlineInput';

const ObjectType = ({
	item,
	register,
	setValue,
	defaultValues,
	errors,
	watch,
	control,
	valueInput,
}) => {
	return (
		<div className="object-wrapper">
			<div className="object-title">
				<span>
					{item.name.includes('.')
						? item.name.split('.')[item.name.split('.').length - 1]
						: item.name}
				</span>
			</div>
			<Row className="d-flex align-items-center">
				{item &&
					item.childs &&
					item.childs.length > 0 &&
					item.childs.map((child) => {
						return (
							<Col
								key={`${item.name}.${child.name}`}
								md={
									child.type === 'OBJECT' ||
									child.type === 'LIST' ||
									child.inputType === 'textarea'
										? 12
										: 6
								}
							>
								<FormField
									item={{ ...child, name: `${item.name}.${child.name}` }}
									register={register}
									errors={errors}
									watch={watch}
									setValue={setValue}
									defaultValues={
										child.defaultValue
											? {
													[`${item.name}.${child.name}`]: child.defaultValue,
											  }
											: defaultValues
									}
									control={control}
									valueInput={valueInput}
								/>
							</Col>
						);
					})}
			</Row>
		</div>
	);
};

ObjectType.propTypes = {
	item: PropTypes.objectOf(PropTypes.any).isRequired,
};

ObjectType.defaultProps = {};

export default ObjectType;
