/**
 *
 * @param {String} sidebarTitle
 * @param {String} name
 * @param {String} title
 */
function createLink(
	sidebarTitle,
	name,
	title,
	id = 'no_anchor',
	parent = null
) {
	if (parent && parent.sidebarTitle) {
		if (sidebarTitle && sidebarTitle.trim() !== '' && sidebarTitle.length > 0) {
			return `${parent.sidebarTitle.replace(
				/\s+/g,
				'_'
			)}_${sidebarTitle.replace(/\s+/g, '_')}`;
		}
	}

	if (sidebarTitle && sidebarTitle.trim() !== '' && sidebarTitle.length > 0) {
		return `${sidebarTitle.replace(/\s+/g, '_')}`;
	}
	const sectionId = `${name}${title}`;
	if (sectionId && sectionId.trim() !== '' && sectionId.length > 0) {
		return sectionId.replace(/\s+/g, '_');
	}
	return id;
}

function getItemUrl(item) {
	if (item.url) {
		return item.url;
	}
	let url = '#';
	if (item.page) {
		if (item.page.pageType === 'API') {
			url = `/api/${item.page.slug}`;
		} else {
			url = `/${item.page.slug}`;
		}
	}

	return url;
}

module.exports = { createLink, getItemUrl };
