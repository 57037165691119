/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import FormField from './FormField';

const ArrayItem = ({
	fields,
	register,
	fieldName,
	defaultValues,
	errors,
	watch,
	setValue,
	control,
	valueInput,
}) => {
	return (
		<>
			{fields.map((item) => {
				return (
					<div key={`${fieldName}.${item.name}`}>
						<FormField
							item={{ ...item, name: `${fieldName}.${item.name}` }}
							register={register}
							errors={errors}
							watch={watch}
							setValue={setValue}
							defaultValues={defaultValues}
							control={control}
							valueInput={valueInput}
						/>
					</div>
				);
			})}
		</>
	);
};

ArrayItem.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.any).isRequired,
};

ArrayItem.defaultProps = {};

export default ArrayItem;
