/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand } from 'reactstrap';
import { Link } from 'gatsby';
import NavPublic from './NavPublic';
import ispLogo from '../../../images/png/isp_logo_white.png';

import ROUTES from '../../utils/routes';

function NavbarWidget(props) {
	const {
		siteTitle,
		color,
		classNameCustom,
		menu,
		isPrivate,
		path,
		onChange,
		closeMenu,
		classStyle,
		isLogged,
		isIspConnectText,
	} = props;

	const [scrollPos, setScrollPos] = useState(0);

	const changeScrollPos = (sp) => {
		setScrollPos(sp);
	};

	const classNameNavbar =
		color === 'dark'
			? 'py-3 header-padding dark-header'
			: 'py-3 header-padding light-header';

	const [isOpen, setIsOpen] = useState(false);
	const [isSync, setIsSync] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
		if (typeof onChange !== 'undefined') onChange(!isOpen);
	};

	useEffect(() => setIsOpen(false), [closeMenu]);
	useEffect(() => setIsSync(true), []);
	const publicMenu = menu && menu.filter((item) => !item.private);
	// const privateMenu = menu && menu.filter((item) => item.private);

	let position = isLogged ? (isPrivate ? '' : 'fixed') : 'fixed';
	if (
		path === '/privacy-policy' ||
		path === '/terms' ||
		path.includes('post')
	) {
		position = 'sticky';
	}

	useEffect(() => {
		const handleScroll = () => {
			const winScroll =
				document.body.scrollTop || document.documentElement.scrollTop;

			const height =
				document.documentElement.scrollHeight -
				document.documentElement.clientHeight;

			const scrolled = winScroll / height;
			changeScrollPos(scrolled);
		};
		if (typeof window !== 'undefined')
			window.addEventListener('scroll', handleScroll);
		return () =>
			typeof window !== 'undefined' &&
			window.removeEventListener('scroll', handleScroll);
	}, []);

	const classNameOriginal = '';

	return (
		isSync && (
			<React.Fragment key={`${isLogged ? 'private' : 'public'}`}>
				<Navbar
					light
					expand="lg"
					className={`${classNameNavbar} ${classNameCustom} ${
						scrollPos > 0 ? classStyle : classNameOriginal
					}`}
					style={{
						width: '100%',
						position,
						top: '0',
						zIndex: '11',
						backgroundColor: '#293039',
						borderBottom: '3px solid white',
					}}
				>
					<div className="d-flex w-100 container">
						<Link to={ROUTES.HOME} className="link-no-hover">
							{isIspConnectText ? (
								<div className="d-flex flex-column justify-content-center align-items-center">
									<span className="isp-logo-font-title">ISP</span>
									<span className="isp-logo-font-subtitle">Disconnect</span>
								</div>
							) : (
								<NavbarBrand
									tag="img"
									src={`${ispLogo}`}
									alt={`${siteTitle}`}
									style={{ width: 175 }}
								/>
							)}
						</Link>
						<NavbarToggler onClick={toggle} />
						<Collapse isOpen={isOpen} navbar>
							<NavPublic color={color} menu={publicMenu} path={path} />
						</Collapse>
					</div>
				</Navbar>
			</React.Fragment>
		)
	);
}

export default NavbarWidget;
