import React from 'react';
import PropTypes from 'prop-types';
import IphoneFrame from './IphoneFrame';
import IpadFrame from './IpadFrame';

const Frame = ({ videoFrame, children }) => {
	return (
		<>
			{(() => {
				switch (videoFrame) {
					case 'IPHONE':
						return (
							<div className="d-flex justify-content-center">
								<IphoneFrame>{children}</IphoneFrame>
							</div>
						);
					case 'IPAD':
						return (
							<div className="d-flex justify-content-center">
								<IpadFrame>{children}</IpadFrame>
							</div>
						);

					default:
						return (
							<div className="d-flex flex-column h-100 justify-content-center">
								{children}
							</div>
						);
				}
			})()}
		</>
	);
};

Frame.propTypes = {
	videoFrame: PropTypes.string,
};

Frame.defaultProps = {
	videoFrame: null,
};

export default Frame;
