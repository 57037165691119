/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import OutlineInput from './OutlineInput';
import ArrayType from './ArrayType';
import ObjectType from './ObjectType';
import CheckBoxInput from './CheckBoxInput';
import RadioInput from './RadioInput';
import SelectInput from './SelectInput';
import MultiSelect from './MultiSelect';
import PlacesAutoComplete from './PlacesAutoComplete';

const FormField = ({
	item,
	register,
	errors,
	watch,
	setValue,
	defaultValues,
	control,
	valueInput,
	showinfoIcon,
}) => {
	return (
		<>
			{!item.type ||
				(item.type === 'INPUT' && (
					<OutlineInput
						name={item.name}
						label={item.label}
						inputId={item.name}
						required={item.required}
						maxLength={item.dataLength}
						register={register}
						errors={errors}
						watch={watch}
						sample={item.sample}
						setValue={setValue}
						defaultValues={defaultValues}
						inputType={item.inputType}
						dataType={item.dataType}
						valueInput={valueInput}
						readOnly={item.readOnly}
						control={control}
						description={item.description}
						showinfoIcon={showinfoIcon}
					/>
				))}
			{item.type === 'GOOGLEPLACES' && (
				<PlacesAutoComplete
					name={item.name}
					label={item.label}
					inputId={item.name}
					required={item.required}
					maxLength={item.dataLength}
					register={register}
					errors={errors}
					watch={watch}
					sample={item.sample}
					setValue={setValue}
					defaultValues={defaultValues}
					inputType={item.inputType}
					dataType={item.dataType}
					valueInput={valueInput}
					readOnly={item.readOnly}
					control={control}
					description={item.description}
					showinfoIcon={showinfoIcon}
				/>
			)}
			{item.type === 'LIST' && (
				<ArrayType
					item={item}
					register={register}
					errors={errors}
					watch={watch}
					sample={item.sample}
					setValue={setValue}
					defaultValues={defaultValues}
					control={control}
					valueInput={valueInput}
				/>
			)}
			{item.type === 'OBJECT' && (
				<ObjectType
					item={item}
					register={register}
					errors={errors}
					watch={watch}
					setValue={setValue}
					defaultValues={defaultValues}
					control={control}
					valueInput={valueInput}
				/>
			)}
			{item.type === 'CHECKBOX' && (
				<CheckBoxInput
					name={item.name}
					label={item.label}
					required={item.required}
					register={register}
					errors={errors}
					watch={watch}
					sample={item.sample}
					setValue={setValue}
					defaultValues={defaultValues}
				/>
			)}
			{item.type === 'RADIO' && (
				<RadioInput
					name={item.name}
					label={item.label}
					required={item.required}
					register={register}
					errors={errors}
					watch={watch}
					sample={item.sample}
					setValue={setValue}
					defaultValues={defaultValues}
				/>
			)}
			{item.type === 'SELECT' && (
				<SelectInput
					item={item}
					errors={errors}
					control={control}
					defaultValues={defaultValues}
					setValue={setValue}
				/>
			)}
			{item.type === 'MULTI-SELECT' && (
				<MultiSelect
					item={item}
					register={register}
					errors={errors}
					setValue={setValue}
					defaultValues={defaultValues}
				/>
			)}
			{item.type === 'HIDDEN' && (
				<div className="mb-2">
					<input
						type="hidden"
						name={item.name}
						value={item.default}
						ref={register}
					/>
				</div>
			)}
		</>
	);
};

FormField.propTypes = {
	item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FormField;
