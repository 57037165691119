/* eslint-disable no-nested-ternary */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
// import { FaLinkedin } from 'react-icons/fa';
// import ListWidget from '../../../shared/List';
// import twitterIcon from '../../../../images/png/logo-twitter.png';
// import LinkWidget from '../../../shared/Link';
// import linkUtils from '../../../utils/linkUtils';
// import instagramIcon from '../../../../images/png/logo-instagram.png';

export default function FooterWidget(props) {
	// const { footerTopMenu, footerBottomMenu } = props;
	const { footerBottomMenu } = props;

	// function convertoListWidgetItems(items) {
	// 	return items.map((item) => {
	// 		const pagePath = item.page ? `/${item.page.slug}` : '#';
	// 		const sectionPath =
	// 			item.section &&
	// 			linkUtils.createLink(
	// 				item.section.sidebarTitle,
	// 				item.section.name,
	// 				item.section.title,
	// 				item.section.id
	// 			);
	// 		let itemUrl = pagePath
	// 			? sectionPath
	// 				? `${pagePath}#${sectionPath}`
	// 				: `${pagePath}`
	// 			: null;
	// 		if (item.url) {
	// 			itemUrl = item.url;
	// 		}
	// 		return { text: item.text, href: itemUrl, type: 'internal' };
	// 	});
	// }

	return (
		<Container>
			{/* <Row>
				<Col md="3" xs="6" className="text-left">
					<span className="text-white text-copyright">
						© COPYRIGHT ISP DISCONNECT 2021
					</span>
				</Col>
				{footerTopMenu &&
					footerTopMenu.length > 0 &&
					footerTopMenu.map((menu) => {
						const pagePath = menu.page ? `/${menu.page.slug}` : null;
						const sectionPath =
							menu.section && menu.section.sidebarTitle
								? menu.section.sidebarTitle.replace(/\s+/g, '_')
								: menu.section && menu.section.title
								? menu.section.title.replace(/\s+/g, '_')
								: null;
						let menuUrl = pagePath
							? sectionPath
								? `${pagePath}#${sectionPath}`
								: `${pagePath}`
							: null;
						if (menu.url) {
							menuUrl = menu.url;
						}
						return (
							<>
								<Col md="3" xs="6" className="text-left" key={`${menu.text}-`}>
									{menuUrl ? (
										<LinkWidget
											href={menuUrl}
											text={menu.text}
											type="internal"
											className={`${menu.className} footer-menu-head`}
										/>
									) : (
										menu.text
									)}
									{menu.menu_items && menu.menu_items.length > 0 && (
										<ListWidget
											data={convertoListWidgetItems(menu.menu_items)}
											className="text-left text-white text-copyright"
										/>
									)}
								</Col>
							</>
						);
					})}
			</Row> */}
			{/* <hr style={{ borderTopColor: 'rgba(255, 255, 255, 0.1)' }} /> */}
			{/* <Row className="py-4 d-none d-md-flex">
				<Col
					md="8"
					xs="12"
					className="d-flex justify-content-center justify-content-md-start mb-4 mb-md-0"
				>
					{footerBottomMenu &&
						footerBottomMenu.length > 0 &&
						footerBottomMenu.map((item) => {
							const pagePath = item.page ? `/${item.page.slug}` : '#';
							const sectionPath =
								item.section && item.section.sidebarTitle
									? item.section.sidebarTitle.replace(/\s+/g, '_')
									: '';
							let itemUrl = pagePath
								? sectionPath
									? `${pagePath}#${sectionPath}`
									: `${pagePath}`
								: null;
							if (item.url) {
								itemUrl = item.url;
							}
							return itemUrl ? (
								<Link
									to={itemUrl}
									className=" text-small mr-3 text-darkgray"
									key={`${itemUrl}-${item.text}`}
								>
									{item.text}
								</Link>
							) : (
								<span
									className="text-small  mr-3 d-block text-darkgray"
									key={`${item.text}--`}
								>
									{item.text}
								</span>
							);
						})}
				</Col>
				<Col
					md="4"
					xs="12"
					className="d-flex justify-content-center justify-content-md-end mb-4 mb-md-0"
				>
					<a
						href="http://twitter.com/apexedgeco"
						className=" text-small mr-4"
						target="_blank"
						rel="noreferrer"
					>
						<img src={twitterIcon} alt="Twitter icon" />
					</a>
					<a
						href="https://www.linkedin.com/company/apexedgeco"
						className=" text-small mr-4"
						target="_blank"
						rel="noreferrer"
					>
						<FaLinkedin size="25" color="#5F6574" />
					</a>
					/* <a
						href="https://www.instagram.com/realbillshark/"
						className=" text-small"
					>
						<img src={instagramIcon} alt="Instagram icon" />
					</a> 
				</Col>
			</Row> */}
			<Row className="py-2 d-flex">
				<Col
					md="6"
					xs="12"
					className="d-flex justify-content-center justify-content-md-start mb-4 mb-md-0"
				>
					<span className="text-white text-small">
						ISP DISCONNECT<sup style={{ fontSize: 10 }}>TM </sup>
						<span style={{ marginLeft: 5 }}>2021</span>
					</span>
				</Col>
				<Col
					md="6"
					xs="12"
					className="d-flex justify-content-center justify-content-md-end mb-4 mb-md-0"
				>
					{footerBottomMenu &&
						footerBottomMenu.length > 0 &&
						footerBottomMenu.map((item) => {
							const pagePath = item.page ? `/${item.page.slug}` : '#';
							const sectionPath =
								item.section && item.section.sidebarTitle
									? item.section.sidebarTitle.replace(/\s+/g, '_')
									: '';
							let itemUrl = pagePath
								? sectionPath
									? `${pagePath}#${sectionPath}`
									: `${pagePath}`
								: null;
							if (item.url) {
								itemUrl = item.url;
							}
							return itemUrl ? (
								<Link
									to={itemUrl}
									className="text-small mr-3 text-darkgray"
									key={`${itemUrl}-${item.text}++-`}
								>
									{item.text}
								</Link>
							) : (
								<span
									className="text-small mt-3 mt-md-0 d-block text-darkgray w-100 text-center "
									key={`${item.text}--+`}
								>
									{item.text}
								</span>
							);
						})}
				</Col>
			</Row>
			{/* <Row className="pb-0 d-flex d-md-none">
				<Col
					md="6"
					xs="12"
					className="d-flex justify-content-center justify-content-md-end mb-4 mb-md-0"
				>
					<a
						href="http://twitter.com/apexedgeco"
						className=" text-small mr-4"
						target="_blank"
						rel="noreferrer"
					>
						<img src={twitterIcon} alt="Twitter icon" />
					</a>
					<a
						href="https://www.linkedin.com/company/apexedgeco"
						className=" text-small mr-4"
						target="_blank"
						rel="noreferrer"
					>
						<FaLinkedin size="25" color="#5F6574" />
					</a>
					 <a
						href="https://www.instagram.com/realbillshark/"
						className=" text-small"
					>
						<img src={instagramIcon} alt="Instagram icon" />
					</a>
				</Col>
			</Row> */}
		</Container>
	);
}
