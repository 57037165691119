import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditorWidget from '../../../../shared/Editor';
import contentParser from '../../../../utils/contentParser';
// import FieldContainer from '../../../sections/FieldContainer/FieldContainer';

const CodeType = (props) => {
	// eslint-disable-next-line prefer-const
	let { title, content, apiId } = props;
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	content = contentParser.codeParser(content);
	const lang = contentParser.getLanguageToCode(content);
	if (lang === 'css') return null;
	return (
		<>
			<blockquote>{title}</blockquote>
			<EditorWidget code={content} apiId={apiId} codeBgColor="dark" />
			{apiId && (
				<>
					<button type="button" className="btn btn-success" onClick={toggle}>
						Execute
					</button>
				</>
			)}
		</>
	);
};

CodeType.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
};

CodeType.defaultProps = {
	title: null,
	content: null,
};

export default CodeType;
