import React from 'react';
import PropTypes from 'prop-types';
import GatsbyNavItem from './GatsbyNavItem';

function GatsbyImageItem(props) {
	const { href, className, children } = props;
	return (
		<GatsbyNavItem href={href} className={className}>
			{children}
		</GatsbyNavItem>
	);
}

GatsbyImageItem.propTypes = {
	href: PropTypes.string.isRequired,
	className: PropTypes.string,
};

GatsbyImageItem.defaultProps = {
	className: '',
};

export default GatsbyImageItem;
