import React from 'react';

const IpadFrame = ({ children }) => {
	return (
		<div className="ipad-frame">
			<div className="ipad-sides">{children}</div>
		</div>
	);
};

export default IpadFrame;
