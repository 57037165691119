import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import ModalImage from 'react-modal-image';
// import ReactPlayer from 'react-player/file';
import Headings from '../../../../shared/Headings/Headings';
import contentParser from '../../../../utils/contentParser';

// const getUrlArray = (videoUrl) => {
// 	const vUrl = [{ src: videoUrl, type: 'video/webm' }];
// 	if (videoUrl.includes('.webm'))
// 		vUrl.push({
// 			src: videoUrl.replace('.webm', '.mp4'),
// 			type: 'video/mp4',
// 		});
// 	return vUrl;
// };
function ColType(props) {
	// eslint-disable-next-line prefer-const
	const {
		content,
		imgUrl,
		videoUrl,
		title,
		isPrivate,
		className,
		headingTitle,
	} = props;

	const transform = (node, index) => {
		const item = { ...node };

		// all links must open in a new window
		if (
			item.type === 'tag' &&
			item.name === 'a' &&
			item.attribs.href.includes('http')
		) {
			item.attribs.target = '_blank';
			return convertNodeToElement(item, index, transform);
		}

		if (
			item.type === 'tag' &&
			item.name === 'figure' &&
			item.attribs.class !== 'table' &&
			isPrivate
		) {
			const childs = item.children;
			return childs.map((el) => {
				return (
					el.type === 'tag' &&
					el.name === 'img' && (
						<ModalImage
							small={el.attribs.src}
							medium={el.attribs.src}
							large={el.attribs.src}
							key={el.attribs.src}
							hideDownload={false}
							hideZoom={false}
							alt={title || 'No alt text provided'}
							className={`img-fluid mx-auto ${el.attribs.class} align-self-center`}
						/>
					)
				);
			});
		}

		return undefined;
	};

	const options = {
		decodeEntities: true,
		transform,
	};

	const contentParsed = contentParser.imageParser(content);
	return (
		<>
			{title && (
				<Headings
					tag={headingTitle}
					className={`${isPrivate ? '' : 'display-4'}`}
				>
					{title}{' '}
				</Headings>
			)}
			{imgUrl && !videoUrl && (
				<div className="d-flex justify-content-center image-section">
					{isPrivate ? (
						<ModalImage
							small={imgUrl}
							medium={imgUrl}
							large={imgUrl}
							hideDownload={false}
							hideZoom={false}
							alt={title || 'No alt text provided'}
							className={`img-fluid mx-auto ${className} align-self-center`}
						/>
					) : (
						<img
							src={imgUrl}
							alt={title || 'No alt text provided'}
							className={`img-fluid mx-auto ${className} align-self-center`}
						/>
					)}
				</div>
			)}
			{/* {videoUrl && (
				<ReactPlayer
					className="react-player-custom"
					url={getUrlArray(videoUrl)}
					// url={videoUrl}
					playing
					volume={0}
					muted
					loop
					width="fit-content"
					height="fit-content"
					controls
					config={{
						attributes: {
							style: {
								width: '280px',
								height: 'auto',
							},
						},
					}}
				/>
			)} */}
			<div className={`heading-level-${headingTitle}`}>
				{contentParsed && ReactHtmlParser(contentParsed, options)}
			</div>
		</>
	);
}

ColType.propTypes = {
	title: PropTypes.string,
	imgUrl: PropTypes.string,
	content: PropTypes.string,
};

ColType.defaultProps = {
	title: null,
	imgUrl: null,
	content: null,
};

export default ColType;
